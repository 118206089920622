@use "../global" as global;

.p-top-bottom-slider {
  height: 115px;
  width: 100%;
  position: relative;
  @include global.mq(sp) {
    height: 14.974vw;
  }
  &::before {
    content: "";
    height: 115px;
    position: absolute;
    width: 100%;
    animation: bgBottom 30s linear infinite;
    background: url(../img/top/img_slider_footer.webp);
    background-size: 1395px;
    left: 0;
    top: 0;
    @include global.mq(sp) {
      height: 14.974vw;
      background-size: 181.641vw;
      animation: bgBottomSp 30s linear infinite;
    }
  }
  @keyframes bgBottom {
    from {
      background-position: 0;
    }

    to {
      background-position: -1395px;
      @include global.mq(sp) {
        background-position: -181.641vw;
      }
    }
  }
  @keyframes bgBottomSp {
    from {
      background-position: 0;
    }

    to {
      background-position: -181.641vw;
    }
  }
}
