@use "../global" as global;

.c-speech-bubble {
  text-align: center;
  &__text {
    display: inline-block;
    margin: 0 auto;
    padding: 6px 30px 8px;
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0.08em;
    background: global.$primary-color;
    color: #fff;
    border-radius: 43px;
    text-align: center;
    position: relative;
    @include global.mq(sp) {
      max-width: 79.167vw;
      padding: 1.302vw 6.25vw 1.563vw;
      font-size: 3.5rem;
      line-height: 1.6;
      border-radius: 999px;
      letter-spacing: normal;
    }
    &::after {
      position: absolute;
      content: "";
      bottom: -9px;
      left: 50%;
      width: 30px;
      height: 10px;
      transform: translateX(-50%);
      transition: 0.2s linear;
      background: url(../img/common/deco_speech-bubble.svg) no-repeat center center / contain;
      @include global.mq(sp) {
        width: 6.25vw;
        height: 2.083vw;
        bottom: -1.823vw;
      }
    }
  }
}
