@use "../global" as global;

.p-about-scheme {
  &__ttl {
    text-align: center;
    h2 {
      font-size: 5rem;
      font-weight: bold;
      display: inline-block;
      position: relative;
      line-height: 1;
      padding-top: 34px;
      padding-left: 76px;
      padding-right: 76px;
      letter-spacing: 0.06em;
      @include global.mq(sp) {
        font-size: 5rem;
        line-height: 1.2;
        padding-top: 1.302vw;
        padding-left: 14.844vw;
        padding-right: 14.844vw;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 80px;
        height: 80px;
        @include global.mq(sp) {
          width: 10.417vw;
          height: 10.417vw;
          top: 30%;
        }
      }
      &::before {
        left: 0;
        background: url(../img/about/icon_quote_left.svg) no-repeat center / contain;
      }
      &::after {
        right: 0;
        background: url(../img/about/icon_quote_right.svg) no-repeat center / contain;
      }
      .is-br{
        display: none;
        @media screen and (max-width: 999px) {
          display: block;
          line-height: 0;
        }
      }
    }
  }
  &__contents {
    margin-top: 40px;
    @include global.mq(sp){
      margin-top: 5.208vw;
    }
  }
  &__box {
    background-color: #fff;
    border: 4px solid global.$secondary-color;
    padding: 40px 10% 60px;
    @include global.mq(sp){
      padding: 5.208vw 6.51vw;
      border: 0.78vw solid global.$secondary-color;
    }
  }
  &__box:nth-of-type(2) {
    margin-top: 26px;
    @include global.mq(sp){
      margin-top: 3.906vw;
    }
  }
  &__heading {
    letter-spacing: 0.02em;
    font-weight: 900;
    font-size: 3.6rem;
    text-align: center;
    @include global.mq(sp){
      font-size: 4rem;
      line-height: 1.4;
    }
    .txt-important {
      color: global.$primary-color;
    }
    .small {
      font-size: 2.4rem;
      @include global.mq(sp){
        font-size: 3.2rem;
      }
    }
    .is-br{
      display: none;
      @media screen and (max-width: 870px) {
        display: block;
      }
    }
  }
  &__image {
    margin-top: 40px;
    @include global.mq(sp){
      margin-top: 3.906vw;
    }
  }
  &__text {
    margin-top: 40px;
    @include global.mq(sp){
      font-size: 2.6rem;
      line-height: 1.8;
      margin-top: 3.906vw;
    }
    p {
      line-height: 1.8;
      letter-spacing: 0.06em;
      font-weight: 500;
    }
    p:not(:first-child) {
      margin-top: 24px;
      @include global.mq(sp){
        margin-top: 5.208vw;
      }
    }
  }
  &__note{
    text-align: center;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.4;
    letter-spacing: 0.6px;
    margin-top: 40px;
    @include global.mq(sp){
      font-size: 3rem;
      line-height: 1.6;
      letter-spacing: normal;
      margin-top: 8.813vw;
    }
    .is-br{
      display: none;
      @media screen and (max-width:880px) {
        display: block;
      }
    }
  }
  &__btn{
    margin-top: 40px;
    @include global.mq(sp){
      margin-top: 3.646vw;
    }
  }
}
