@use "../global" as global;

.p-top-about {
  padding: 72px 0 0;
  &__suggestion {
    max-width: 1120px;
    width: 100%;
    margin: 20px auto;
    @include global.mq(sp) {
      margin: 2.6vw auto;
    }
  }
  &__slider {
    margin-top: 80px;
    position: relative;
    height: 428px;
    @include global.mq(sp) {
      height: 55.729vw;
      margin-top: 6.51vw;
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url(../img/top/img_top-about-slider.webp);
      background-size: 1920px;
      animation: aboutLoopRight 30s linear infinite;
      @include global.mq(sp) {
        // background-size: 250vw;
        background-size: cover;
        animation: aboutLoopRightSp 20s linear infinite;
      }
    }
    @keyframes aboutLoopRight {
      from {
        background-position: 0;
      }
      to {
        background-position: -1920px;
      }
    }
    @keyframes aboutLoopRightSp {
      from {
        background-position: 0;
      }
      to {
        background-position: -250vw;
      }
    }
    // display: flex;
    // overflow: hidden;
    // @include global.mq(sp) {
    //   margin-top: 6.51vw;
    // }
    // .p-slider {
    //   @include global.mq(sp) {
    //     animation: scroll-left 50s infinite linear 0.5s both;
    //   }
    // }
    // .p-slider__item {
    //   max-width: 100%;
    //   width: 100%;
    //   @include global.mq(sp) {
    //     width: 250vw;
    //   }
    // }
  }
  &__message {
    text-align: center;
    font-weight: bold;
    font-size: 2.8rem;
    font-size: clamp(2.2rem, 1.799rem + 0.83vw, 2.8rem);
    line-height: 1.8;
    margin-top: 80px;
    @include global.mq(sp) {
      font-size: 3.2rem;
      margin-top: 9.115vw;
      line-height: 1.7;
    }
    p:not(:first-child) {
      margin-top: 50px;
      @include global.mq(sp) {
        margin-top: 6.51vw;
      }
    }
    .txt-important {
      color: global.$primary-color;
      font-size: 3.6rem;
      font-size: clamp(2.6rem, 1.932rem + 1.39vw, 3.6rem);
      @include global.mq(sp) {
        font-size: 4.2rem;
      }
    }
  }
  &__description {
    margin-top: 93px;
    @include global.mq(sp) {
      margin-top: 16.667vw;
    }
  }
  &__qualification {
    margin-top: 58px;
    @include global.mq(sp) {
      margin-top: 9.115vw;
    }
  }
  &__note {
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.06em;
    line-height: 1.8;
    margin-top: 30px;
    @include global.mq(sp) {
      font-size: 2.6rem;
      text-align: left;
    }
  }
  &__btn {
    margin-top: 60px;
    @include global.mq(sp) {
      margin-top: 5.208vw;
    }
  }
}
