@use "../global" as global;

// ベース
html {
  font-size: 62.5%;
  // @media screen and (max-width:1920px){
  //   font-size: 0.625vw;
  // }
  @include global.mq(sp) {
    font-size: 1.3020833333vw;
  }
}

body {
  font-family: global.$font-family;
  line-height: 1.6;
  color: global.$text-color;
  background-color: #fff;
  @media screen and (min-width: 769px) {
    font-size: 1.6rem;
  }
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  display: inline-block;
  &:hover {
    // opacity: 0.7;
  }
}
@media (min-width: 751px) {
  a[href^="tel:"] {
    pointer-events: none;
    cursor: default;
  }
}
li,
dd {
  list-style-type: none;
}
h5,
h6 {
  margin: 0;
}
ul,
ol {
  margin: 0;
  padding: 0;
}
button {
  border: 0;
  padding: 0;
  margin: 0;
}
.is-pc {
  display: block;
  @include global.mq(sp) {
    display: none;
  }
}
.is-sp {
  display: none;
  @include global.mq(sp) {
    display: block;
  }
}
.is-tab {
  display: none;
  @media screen and (max-width: 950px) {
    display: block;
  }
}

/* スクロールすると下からふわっとさせるためのコード */
.fadeUp {
  /* 50px下げる */
  transform: translateY(50px);
  /* 要素を透明にする */
  opacity: 0;
  /* 2秒かけて、変化させる */
  transition:
    transform 1s,
    opacity 1s;
}

.fadeUp.show {
  transform: translateY(0);
  opacity: 1;
}

.top-fadeUp {
  animation-name: top-fadeUp;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes top-fadeUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
