@use "../global" as global;

.p-privacy-policy {
  max-width: 960px;
  margin: 0 auto;
  height: 112px;
  overflow-y: scroll;
  padding: 20px;
  border: 1px solid #dadada;
  line-height: 1.5;
  border-radius: 4px;
  @include global.mq(sp) {
    padding: 3.906vw 5.208vw 3.906vw 3.906vw;
    height: 39.063vw;
  }
  p {
    font-size: 1.2rem;
    font-weight: 500;
    @include global.mq(sp) {
      font-size: 2.2rem;
      line-height: 2;
      letter-spacing: 0.06em;
    }
  }
  p:not(:first-child) {
    margin-top: 20px;
    @include global.mq(sp) {
      margin-top: 4.688vw;
    }
  }
  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f8f8f8;
    @include global.mq(sp) {
      width: 2.604vw;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border-radius: 6px;
    @include global.mq(sp) {
      border-radius: 1.563vw;
    }
  }
  // Firefox
  scrollbar-width: 10px;
  scrollbar-color: #dadada;
  @include global.mq(sp) {
    scrollbar-width: 2.604vw;
  }
}
