@use "../global" as global;

.p-slider{
  @include global.mq(sp){
    flex-direction: column;
  }
  &__wrapper{
    display: flex;
    overflow: hidden;
    @include global.mq(sp){
      flex-direction: column;
    }
  }
  &__item{
    // width: calc(100% / 4);
    width: 23.7vw;
    max-width: 455px;
    @include global.mq(sp){
      width: 100%;
    }
  }
  @keyframes scroll-left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  @keyframes scroll-right {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes scroll-top {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes scroll-bottom {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
}
.p-mv__slider-top{
  .p-slider{
    display: flex;
    animation: scroll-right 50s infinite linear .5s both;
    @include global.mq(sp){
      animation: scroll-top 50s infinite linear .5s both;
    }
  }
}
.p-mv__slider-bottom{
  .p-slider{
    display: flex;
    animation: scroll-left 50s infinite linear .5s both;
    @include global.mq(sp){
      animation: scroll-bottom 50s infinite linear .5s both;
    }
  }
}