@use "../global" as global;

.p-404{
  padding: 52px 0 296px;
  @include global.mq(sp){
    padding: 7.813vw 0 15.625vw;
  }
  &__text{
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    line-height: 1.8;
    @include global.mq(sp){
      font-size: 3rem;
      line-height: 1.6;
    }
  }
  &__text:not(:first-child){
    @include global.mq(sp){
      margin-top: 6.51vw;
    }
  }
  &__btn{
    margin-top: 60px;
    @include global.mq(sp){
      margin-top: 13.021vw;
    }
  }
  .c-button-s{
    span{
      &::before{
        content: none;
      }
    }
  }
}