@use "../global" as global;

.p-product {
  border-radius: 14px;
  overflow: hidden;
  &__head {
    display: flex;
  }
  &__body {
    background-color: #fff;
    padding: 60px 10%;
    position: relative;
    @include global.mq(sp){
      padding: 7.813vw 6.51vw;
    }
  }
  &__logo {
    padding: 20px 60px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 40px 40px 0 0;
    position: absolute;
    top: -94px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 428px;
    width: 100%;
    @include global.mq(sp){
      padding: 2.604vw 7.813vw;
      max-width: 55.729vw;
      top: -12vw;
      // top: -7.812vw;
      border-radius: 5.208vw 5.208vw 0 0;
    }
  }
  &__lead {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.02em;
    @include global.mq(sp){
      font-size: 2.6rem;
      line-height: 1.8;
    }
  }
  &__contents {
    margin-top: 40px;
    @include global.mq(sp){
      margin-top: 5.208vw;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 30px;
    @include global.mq(sp){
      grid-template-columns: repeat(1,1fr);
      gap: 6.51vw;
    }
  }
  &__item {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 3;
    gap: 0;
  }
  &__name {
    text-align: center;
    font-size: 2.4rem;
    line-height: 1;
    margin-top: 20px;
    @include global.mq(sp){
      font-size: 4rem;
      margin-top: 3.906vw;
    }
  }
  &__text {
    margin-top: 14px;
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 500;
    background-color: #f4f4f4;
    border-radius: 0 20px 0 20px;
    @include global.mq(sp){
      font-size: 2.4rem;
      margin-top: 3.906vw;
      padding: 2.604vw;
      border-radius: 0 2.604vw 0 2.604vw;
    }
  }
}
#torte {
  .p-product__item {
    grid-row: span 1;
  }
}
