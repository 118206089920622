@use "../global" as global;

.p-anchor {
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -30px;
    @include global.mq(sp) {
      margin-top: -3.646vw;
    }
  }
  &__item {
    text-align: center;
    max-width: 320px;
    width: 100%;
    margin: 30px 15px 0;
    @include global.mq(sp) {
      max-width: fit-content;
      margin: 3.646vw 1.823vw 0;
    }
  }
  &__link {
    line-height: 1;
    display: inline-block;
    font-size: 2.2rem;
    font-weight: bold;
    padding: 24px 0;
    width: 100%;
    position: relative;
    transition: 0.1s linear;
    transform: translateY(-2px);
    box-shadow: 0 4px 0 0 #cdcdcd;
    background-color: #fff;
    border-radius: 999px;
    @include global.mq(sp) {
      font-size: 3.4rem;
      padding: 3.385vw 11.979vw 4.427vw 6.25vw;
      box-shadow: 0 0.78vw 0 0 #cdcdcd;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      width: 26px;
      height: 26px;
      background: url(../img/brand/icon_anchor.svg) no-repeat center / contain;
      @include global.mq(sp){
        width: 5.469vw;
        height: 5.469vw;
        right: 3.906vw;
      }
    }
    @media (any-hover: hover) {
      &:hover {
        @media screen and (min-width:769px){
          box-shadow: none;
          transform: translateY(2px);
        }
      }
    }
    span {
      margin-right: 20px;
      @include global.mq(sp){
        margin-right: 0;
      }
    }
  }
}