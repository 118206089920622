@use "../global" as global;

.p-result-box {
  border-radius: 14px;
  overflow: hidden;
  background-color: #fff;
  @include global.mq(sp){
    border-radius: 1.823vw;
  }
  &__body {
    padding: 20px 40px 50px;
    padding: 1.042vw 2.083vw 2.604vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include global.mq(sp){
      padding: 3.906vw 6.51vw 4.51vw;
    }
  }
  &__logo {
    max-width: 308px;
    width: 100%;
    @include global.mq(sp){
      max-width: 64.063vw;
    }
  }
  &__earnings {
    line-height: 1;
    font-weight: bold;
    font-size: 3rem;
    font-size: clamp(2.6rem, 2.333rem + 0.56vw, 3rem);
    letter-spacing: 0.08em;
    margin-top: 10px;
    text-align: center;
    @include global.mq(sp){
      font-size: 4.6rem;
      margin-top: 2vw;
      letter-spacing: normal;
    }
    .txt-important {
      color: global.$primary-color;
    }
    .is-tab{
      @include global.mq(sp){
        display: none;
      }
    }
  }
  .num {
    font-size: 6rem;
    font-size: clamp(5rem, 4.332rem + 1.39vw, 6rem);
    letter-spacing: normal;
    @include global.mq(sp){
      font-size: 9.3rem;
    }
  }
  .span{
    font-size: 1.8rem;
    letter-spacing: -0.02em;
    @include global.mq(sp){
      font-size: 2.8rem;
    }
  }
  &__description{
    margin-top: 21px;
    font-weight: 500;
    color: #8D8D8D;
    @include global.mq(sp){
      font-size: 2rem;
      margin-top: 3.646vw;
    }
  }
}
