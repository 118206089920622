@use "../global" as global;

.p-contact {
  padding: 53px 0 120px;
  @include global.mq(sp) {
    padding: 7.813vw 0 8.333vw;
  }
  &__lead {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1;
    margin-top: 51px;
    text-align: center;
    @include global.mq(sp) {
      font-size: 3rem;
      line-height: 1.6;
      margin-top: 6.51vw;
    }
  }
  &__form {
    margin-top: 40px;
    border-radius: 14px;
    // overflow: hidden;
    @include global.mq(sp) {
      margin-top: 8.073vw;
    }
  }
  .form {
    padding: 0 0 62px;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    background-color: #fff;
    border-radius: 0 0 14px 14px;
    @include global.mq(sp) {
      padding: 0 0 8.333vw;
    }
  }
  .customer {
    background-color: #fffce3;
  }
  .title {
    padding: 15px 0;
    color: #fff;
    background-color: #454643;
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: center;
    border-radius: 14px 14px 0 0;
    @include global.mq(sp) {
      font-size: 2.6rem;
      padding: 1.823vw 0 1.953vw;
    }
  }
  .form_item {
    border-bottom: 1px solid #dadada;
    position: relative;
    display: flex;
    @include global.mq(sp) {
      flex-direction: column;
    }
    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }
  dt {
    max-width: 40%;
    width: 100%;
    padding: 28px 30px;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    position: relative;
    @include global.mq(sp) {
      max-width: 100%;
      padding: 2.1vw 3.906vw 2.1vw;
    }
  }
  dt label {
    font-weight: bold;
    @include global.mq(sp) {
      font-size: 2.6rem;
      line-height: 1.8;
      letter-spacing: 0.06em;
    }
  }
  .dt_required {
    padding: 2px 5px 3px;
    display: inline-block;
    color: #e5004f;
    font-size: 1.4rem;
    line-height: 1.4;
    border: 1px solid #e5004f;
    position: absolute;
    right: 20px;
    top: 30px;
    @include global.mq(sp) {
      position: static;
      margin-left: 1.302vw;
      font-size: 2.2rem;
      line-height: 1;
      padding: 0.4vw 1.042vw 0.7vw;
    }
  }
  dd {
    padding: 20px;
    max-width: 60%;
    width: 100%;
    background-color: #fff;
    // margin-left: 480px;
    @include global.mq(sp) {
      max-width: 100%;
      margin-left: 0;
      padding: 3.906vw;
    }
  }
  input[type="text"] {
    padding: 8px 12px;
    color: global.$text-color;
    background-color: #f8f8f8;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    border: 1px solid #dadada;
    border-radius: 4px;
    outline: none;
    max-width: 200px;
    @include global.mq(sp) {
      font-size: 2.8rem;
      padding: 3.604vw 3vw;
      max-width: 100%;
      width: 100%;
    }
    &::placeholder {
      color: #c3c3c3;
    }
  }
  input[type="text"].input-long {
    max-width: 550px;
    width: 100%;
    @include global.mq(sp) {
      max-width: 100%;
    }
  }
  textarea {
    max-width: 550px;
    width: 100%;
    height: 350px;
    padding: 8px 12px;
    color: global.$text-color;
    background-color: #f8f8f8;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    border: 1px solid #dadada;
    border-radius: 4px;
    outline: none;
    @include global.mq(sp) {
      font-size: 2.8rem;
      padding: 2.604vw 3.255vw;
      max-width: 100%;
      height: 32.552vw;
    }
    &::placeholder {
      color: #c3c3c3;
    }
  }
  .submit-wrapper {
    text-align: center;
    padding: 40px 0 0;
    background: #fff;
    position: relative;
    @include global.mq(sp) {
      padding-top: 7.813vw;
    }
  }
  .submit {
    position: relative;
    display: inline-block;
    max-width: 598px;
    width: 100%;
    margin: 0 auto;
    display: block;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1;
    background: global.$btn-color;
    transform: translateY(-4px);
    box-shadow: 0 8px 0 0 #ffba00;
    border-radius: 16px;
    text-align: center;
    position: relative;
    transition: 0.1s linear;

    @include global.mq(sp) {
      max-width: 78.125vw;
      font-size: 3.8rem;
      box-shadow: 0 1.04vw 0 0 #ffba00;
      border-radius: 2.344vw;
    }
    @media (any-hover: hover) {
      &:hover {
        @media screen and (min-width: 769px) {
          transform: translateY(4px);
          box-shadow: none;
          opacity: 1;
        }
      }
    }
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      right: 54px;
      width: 13px;
      height: 18px;
      transform: translateY(-50%);
      background: url(../img/common/icon_arrow_contact.svg) no-repeat center center / contain;
      @include global.mq(sp) {
        width: 2.083vw;
        height: 2.865vw;
        right: 6.771vw;
      }
    }
  }
  input[type="submit"] {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: global.$text-color;
    width: 100%;
    padding: 44px 0;
    @include global.mq(sp) {
      padding: 5vw 2.865vw 5.7vw 0;
    }
  }
  .contact__confirm {
    text-align: center;
    font-weight: bold;
    line-height: 1.8;
    letter-spacing: 0.06em;
    background-color: #fff;
    padding: 32px 0 28px;
    @include global.mq(sp) {
      font-size: 2.6rem;
      line-height: 1.8;
      padding: 6.51vw 0;
    }
    label {
      margin-left: 4px;
      cursor: pointer;
      @include global.mq(sp) {
        margin-left: 10px;
      }
    }
  }
  input[type="checkbox"] {
    position: relative;
    width: 22px;
    height: 22px;
    border: 1px solid #dadada;
    background-color: #f8f8f8;
    border-radius: 4px;
    vertical-align: -8px;
    appearance: none;
    cursor: pointer;
    @include global.mq(sp) {
      width: 4.688vw;
      height: 4.688vw;
      vertical-align: -1.172vw;
      margin: 2px 0 0 0;
    }
  }
  input[type="checkbox"]:checked:before {
    border-bottom: 3px solid #e5004f;
    border-right: 3px solid #e5004f;
    content: "";
    height: 14px;
    left: 7px;
    position: absolute;
    top: 1px;
    transform: rotate(50deg);
    width: 8px;
    @include global.mq(sp) {
      height: 2.865vw;
      width: 1.563vw;
      top: 0.391vw;
      left: 1.563vw;
      border-bottom: 0.52vw solid #e5004f;
      border-right: 0.52vw solid #e5004f;
    }
  }
  .input-text {
    margin: 19px 0 24px;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 0.8px;
    @include global.mq(sp) {
      font-size: 2.6rem;
      line-height: 1.8;
      margin: 1.804vw 0;
    }
  }
  &__privacy {
    background-color: #fff;
    padding: 0 20px;
    @include global.mq(sp) {
      padding: 0 3.906vw;
    }
  }
  .has_error {
    margin: 10px 0 0;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.8;
    color: red;
    display: block;
    @include global.mq(sp) {
      font-size: 2.6rem;
    }
  }
  .em_privacy {
    text-align: center;
    padding-bottom: 20px;
    margin-top: -20px;
    background-color: #fff;
  }
  .is-tab {
    @include global.mq(sp) {
      display: none;
    }
  }
}
#confirm .p-contact dd {
  padding: 28px 20px;
  @include global.mq(sp) {
    font-size: 2.6rem;
    line-height: 1.8;
    padding: 3.906vw;
    font-weight: 500;
  }
}
#thanks .p-contact {
  @include global.mq(sp) {
    padding: 7.813vw 0 15.625vw;
  }
}
