@use "../global" as global;

.p-mv {
  padding-bottom: 50px;
  @include global.mq(sp){
    padding-bottom: 0;
  }
  &__inner {
    max-width: 100%;
    margin: 0 50px;
    position: relative;
    height: 600px;
    @media screen and (max-width:1700px){
      height: 38vw;
    }
    @include global.mq(sp){
      margin: 3.906vw 3.906vw 0;
      height: 120vw;
    }
    &::before,
    &::after{
      content: "";
      width: 100%;
      height: 300px;
      position: absolute;
      // backface-visibility: hidden;
      @media screen and (max-width:1700px){
        height: 50%;
      }
      @include global.mq(sp){
        height: 120vw;
        width: 50%;
      }
    }
    &::before{
      top: 0;
      left: 0;
      background: url(../img/top/bg_mainvisual_top.webp);
      background-size: 3640px;
      animation: bgLoopLeft 50s linear infinite;
      @include global.mq(sp){
        background: url(../img/top/bg_mainvisual_top_sp.webp);
        animation: bgLoopTop 20s linear infinite;
        background-size: 100%;

      }
    }
    &::after{
      bottom: 0;
      right: 0;
      background: url(../img/top/bg_mainvisual_bottom.webp);
      background-size: 3640px;
      animation: bgLoopRight 50s linear infinite;
      @include global.mq(sp){
        background: url(../img/top/bg_mainvisual_top_sp.webp);
        animation: bgLoopBottom 20s linear infinite;
        background-size: 100%;

      }
    }
    @keyframes bgLoopLeft {
      from {
          background-position: 0
      }
    
      to {
          background-position: 3640px
      }
    }
    
    @keyframes bgLoopRight {
      from {
          background-position: 0
      }
    
      to {
          background-position: -3640px
      }
    }
    @keyframes bgLoopTop {
      from {
          background-position: 0 0;
      }
    
      to {
          background-position: 0 240vw;
      }
    }
    
    @keyframes bgLoopBottom {
      from {
        background-position: 0 240vw;
      }
    
      to {
          background-position: 0 0;
      }
    }
  }
  &__text {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include global.mq(sp){
      top: 8.594vw;
    }
  }
  &__lead {
    width: 42.7vw;
    max-width: 820px;
    @include global.mq(sp) {
      width: 72vw;
    }
  }
  &__intro{
    max-width: 820px;
    @include global.mq(sp){
      max-width: 72.005vw;
    }
  }
  &__message {
    width: 56vw;
    max-width: 1080px;
    margin-top: 38px;
    @include global.mq(sp) {
      width: 87vw;
      margin-top: 4.948vw;
    }
  }
}
