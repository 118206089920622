@use "../global" as global;

.c-button-bl {
  &__link {
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
    padding: 23px 0;
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1;
    background: global.$btn-black-color;
    color: #fff;
    border: 2px solid global.$btn-black-color;
    border-radius: 43px;
    text-align: center;
    position: relative;
    transition: 0.1s linear;
    @include global.mq(sp) {
      max-width: 79.167vw;
      // padding: 4.167vw 16.536vw;
      padding: 4.167vw 0;
      font-size: 2.8rem;
      line-height: 1.6;
      border-radius: 999px;
    }
    @media (any-hover: hover) {
      &:hover {
        opacity: 1;
        @media screen and (min-width:769px){
          background: #fff;
          color: global.$btn-black-color;
          border: 2px solid global.$btn-black-color;
          &::after{
            background-color: global.$btn-black-color;
            right: 18px;
          }
        }
      }
    }
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      right: 28px;
      width: 8px;
      height: 12px;
      transform: translateY(-50%);
      transition: 0.2s linear;
      mask: url(../img/common/icon_arrow.svg) no-repeat center center / contain;
      background-color: #fff;
      @include global.mq(sp) {
        width: 1.563vw;
        height: 2.344vw;
        right: 6.51vw;
      }
    }
  }
  span {
    padding-right: 4px;
    @include global.mq(sp) {
      padding-right: 0;
    }
  }
}
