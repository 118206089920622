@use "../global" as global;

.p-about{
  padding: 53px 0 150px;
  @include global.mq(sp) {
    padding: 7.813vw 0 15.625vw;
  }
  .p-top-about__suggestion{
    @include global.mq(sp) {
      margin: 4.6vw auto 1vw;
    
    }
  }
  .p-about__heading{
    font-weight: bold;
    font-size: 2.8rem;
    letter-spacing: 0.02em;
    text-align: center;
    @include global.mq(sp){
      font-size: 4rem;
      line-height: 1.4;
      letter-spacing: normal;
    }
    .is-tab{
      @media screen and (max-width:1030px){
        display: block;
      }
    }
  }
}