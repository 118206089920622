@use "../global" as global;

.p-header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: map-get(global.$layer, "header");
  &__content {
    &-top {
      height: 100px;
      overflow: hidden;
      background: url(../img/common/bg_pattern_red.webp) #cd2438;
      background-size: 500px;
      // font-size: 0;
      @include global.mq(sp) {
        background: transparent;
        height: 15.885vw;
      }
      .p-header__inner {
        @media screen and (min-width: 769px) {
          transform: translateY(-50px);
          position: relative;
        }
        @include global.mq(sp){
          display: flex;
          align-items: center;
          padding: 4.948vw 15.625vw 2.865vw 3.906vw;
        }
      }
    }
    &-bottom {
      width: 100%;
      padding: 30px 0 31px;
      background: #fff;
      @include global.mq(sp){
        padding: 3.906vw;
        margin-bottom: 100px;
        .p-header__inner{
          background-color: #F2F2F2;
          padding: 5.99vw 7.031vw 5.729vw;
          background: url(../img/common/bg_pattern.webp) #F2F2F2;
          background-size: 65.104vw;
          // height: 100%;
          // height: 91dvh;
          height: calc(100dvh - 7.812vw - 15.625vw);
          position: relative;
        }
      }
    }
  }
  &__logo {
    display: inline-block;
    @include global.mq(sp){
      max-width: 33.594vw;
      width: 100%;
      a{
        display: block;
      }
    }
    @media screen and (min-width: 769px) {
      width: 200px;
      height: 200px;
      border-radius: 100px;
      background: #fff;
      position: relative;
      vertical-align: middle;
      img {
        position: absolute;
        width: 140px;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        transition: 0.3s;
        &:hover{
          @media (any-hover: hover) {
            opacity: 0.7;
          }
        }
      }
    }
  }
  &__catch {
    margin: 0 0 0 30px;
    padding: 0 0 13px;
    border-bottom: 2px dotted #ffdd00;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    color: #fff;
    font-weight: bold;
    font-size: 1.4rem;
    letter-spacing: 2px;
    @media screen and (max-width:950px){
      line-height: 1.5;
      padding: 0 0 6px;
      .is-tab{
        line-height: 0;
      }
    }
    @media screen and (max-width:790px){
      margin-left: 10px;
    }
    @include global.mq(sp){
      margin: 0 0 0 2.604vw;
      font-size: 2.2rem;
      letter-spacing: normal;
      color: global.$text-color;
      padding: 0;
      border-bottom: none;
      line-height: 1.3;
    }
  }
  &__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    max-width: 270px;
    width: 100%;
    @include global.mq(sp) {
      display: none;
    }
  }
}

.p-header__content-bottom.fixed {
  position: fixed;
  top: 0;
  left: 0;
}
