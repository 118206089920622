@use "../global" as global;

.p-top-description{
  padding: 50px;
  border: 4px solid global.$secondary-color;
  background-color: #fff;
  position: relative;
  @include global.mq(sp){
    padding: 10.417vw 6.51vw 6.51vw;
    border: 0.78vw solid global.$secondary-color;
  }
  &__ttl{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-50%);
    @include global.mq(sp){
      top: -0.911vw;
    }
  }
  &__text{
    line-height: 1.8;
    letter-spacing: 0.06em;
    font-weight: 500;
    @include global.mq(sp){
      font-size: 2.6rem;
      text-align: justify;
    }
    p:not(:first-child){
      margin-top: 28px;
      @include global.mq(sp){
        margin-top: 6.167vw;
      }
    }
  }
  &__image{
    margin-top: 40px;
    @include global.mq(sp){
      margin-top: 8.333vw;
    }
  }
}