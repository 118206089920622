@use "../global" as global;

.l-inner {
  max-width: global.$layout-width-inner;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  @include global.mq(sp){
    padding: 0 3.906vw;
  }
}
