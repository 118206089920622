@use "../global" as global;

.p-intro {
  &__inner {
    padding: 0 20px 58px;
    background: #fff;
    max-width: 1200px;
    position: relative;
    @include global.mq(sp){
      padding: 5.854vw 0 5.729vw;
      border-radius: 0 0 4.167vw 4.167vw;
    }
  }
  &__heading{
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%,-50%);
    @include global.mq(sp){
      top: -4.036vw;
    }
    .c-speech-bubble__text{
      white-space: nowrap;
      @include global.mq(sp){
        line-height: 1.2;
        font-size: 2.8rem;
      }
      @media screen and (max-width:300px){
        white-space: normal;
      }
    }
  }
  &__message {
    text-align: center;
    font-weight: bold;
    font-size: 3.2rem;
    font-size: clamp(2.4rem, 1.866rem + 1.11vw, 3.2rem);
    .txt-important{
      color: global.$primary-color;
    }
    @include global.mq(sp){
      font-size: 3rem;
    }
  }
  &__btn{
    margin-top: 30px;
    @include global.mq(sp){
      margin-top: 3.906vw;
    }
  }
}
