@use "../global" as global;

.p-top-qualification {
  padding: 48px 4.1%;
  border: 4px solid global.$secondary-color;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: space-between;
  @include global.mq(sp) {
    flex-direction: column;
    padding: 10.417vw 6.51vw 6.51vw;
    border: 0.78vw solid global.$secondary-color;
  }
  &__ttl {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    @include global.mq(sp){
      top: -0.911vw;
    }
  }
  &__text {
    background-color: #fff8e4;
    font-weight: 500;
    padding: 40px 40px;
    padding: 2.083vw 2.083vw;
    width: 100%;
    // max-width: 575px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include global.mq(sp){
      padding: 7.031vw 6.51vw 7.031vw;
    }
  }
  &__heading {
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1;
    @include global.mq(sp){
      font-size: 5.8rem;
    }
  }
  &__description {
    margin-top: 20px;
    font-size: 1.3rem;
    @include global.mq(sp){
      margin-top: 4.167vw;
      font-size: 2.4rem;
    }
  }
  &__detail {
    width: 44%;
    // min-width: 485px;
    width: 100%;
    max-width: 485px;
    margin-left: 2.083vw;
    @include global.mq(sp) {
      min-width: auto;
      width: 100%;
      margin-left: 0;
      margin-top: 3.906vw;
      max-width: 100%;
    }
    dt {
      font-size: 2.2rem;
      color: global.$primary-color;
      float: left;
      position: relative;
      padding-left: 48px;
      font-weight: 500;
      @include global.mq(sp){
        font-size: 3.8rem;
        padding-left: 9.896vw;
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        @include global.mq(sp){
          width: 8.333vw;
          height: 8.333vw;
        }
      }
    }
    dt:nth-of-type(1) {
      &::before {
        background: url(../img/top/icon_announce.svg) no-repeat center center / contain;
      }
    }
    dt:nth-of-type(2) {
      &::before {
        background: url(../img/top/icon_caution.svg) no-repeat center center / contain;
      }
    }
    dd {
      line-height: 1.8;
      font-weight: 500;
      margin-left: 125px;
      // margin-left: 6.51vw;
      margin-bottom: 20px;
      @include global.mq(sp){
        margin-left: 26.693vw;
        font-size: 2.6rem;
        margin-bottom: 4.167vw;
      }
      span {
        font-size: 1.3rem;
        @include global.mq(sp){
          font-size: 2.4rem;
        }
      }
    }
    dd:last-child {
      margin-bottom: 0;
    }
  }
}
