@use "../global" as global;

.l-company {
  margin-top: 180px;
  @include global.mq(sp){
    margin-top: 15.625vw;
  }
  &__outline{
    margin-top: 51px;
    @include global.mq(sp){
      margin-top: 6.51vw;
    }
  }
}