@use "../global" as global;

.l-brand {
  margin-top: 180px;
  @include global.mq(sp){
    margin-top: 15.625vw;
  }
  &__anchors{
    margin-top: 52px;
    @include global.mq(sp){
      margin-top: 3.516vw;
    }
  }
  &__products{
    margin-top: 60px;
    @include global.mq(sp){
      margin-top: 7.813vw;
    }
  }
}
