@use "../global" as global;

.p-footer{
  padding: 74px 0 102px;
  @include global.mq(sp){
    padding: 3.906vw 0 23.438vw;
  }
  &__inner{
    display: flex;
    justify-content: space-between;
    @media screen and (max-width:1200px){

      flex-direction: column-reverse;
      padding: 0 32px;
    }
    @include global.mq(sp){
      padding: 0 7.813vw;
    }
  }
  &__company{
    text-align: center;
    @include global.mq(sp){
      margin-top: 14.323vw;
    }
  }
  &__logo a{
    max-width: 170px;
    width: 100%;
    transition: 0.3s;
    @include global.mq(sp){
      max-width: 45.573vw;
    }
    &:hover{
      @media (any-hover: hover) {
        opacity: 0.7;
      }
    }
  }
  &__text{
    font-size: 1.2rem;
    line-height: 1;
    margin-top: 16px;
    font-weight: bold;
    @include global.mq(sp){
      font-size: 2.4rem;
      margin-top: 4.604vw;
    }
  }
  &__contents{
    text-align: right;
    margin-left: 40px;
    @media screen and (max-width:1200px){
      margin-left: 0;
    }
  }
  &__nav{
    &-list{
      display: flex;
      margin-top: -30px;
      @media screen and (max-width:1200px){
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
      }
      @include global.mq(sp){
        margin-top: 0;
        margin-left: -7.813vw;
      }
    }
    &-item{
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 2.1px;
      margin-top: 30px;
      // @media screen and (max-width:1200px){
      //   margin-top: 7.813vw;
      //   font-size: 3.2rem;

      //   margin-left: 3.906vw;
      //   margin-right: 3.906vw;
      // }
      @include global.mq(sp){
        margin-top: 7.313vw;
        font-size: 3.2rem;
        margin-left: 7.813vw;
      }
    }
    &-item:not(:first-child){
      margin-left: 50px;
      @media screen and (max-width:1300px){
        margin-left: 40px;
      }
      @media screen and (max-width:880px){
        margin-left: 20px;
      }
      @media screen and (max-width:800px){
        margin-left: 15px;
      }
      @include global.mq(sp){
        margin-left: 6.51vw;
        // margin-left: 7.813vw;
        // margin-left: 0;

      }
      @media screen and (max-width:378px){
        // margin-left: 5.208vw;
      }
    }
    &-item:nth-of-type(3){
      @include global.mq(sp){
        // margin-left: 0;
      }
    }
    &-item:nth-of-type(6){
      @include global.mq(sp){
        // margin-left: 0;
      }
    }
    &-link{
      position: relative;
      padding-bottom: 3px;
      &::after {
        content: "";
        width: 0;
        height: 1px;
        max-width: 100%;
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: global.$text-color;
        transition: 0.3s;
      }
      &:hover{
        opacity: 1;
        @media screen and (min-width:769px){
          @media (any-hover: hover) {
            &::after{
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__copyright{
    font-size: 1.2rem;
    line-height: 1;
    margin-top: 43px;
    @media screen and (max-width:1200px){
      text-align: center;
      margin-bottom: 10.417vw;
    }
    @include global.mq(sp){
      display: none;
    }
  }
  &.contact-footer{
    padding-bottom: 22px;
    @include global.mq(sp){
      padding-bottom: 16vw;
    }
  }
}