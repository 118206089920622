// フォント
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');

$font-family: "Noto Sans JP", sans-serif;

// カラー
$primary-color: #E5001B;
$secondary-color: #F8CE5F;
$bg-color: #F2F2F2;
$text-color: #434343;
$btn-color: #FFE617;
$btn-black-color: #222222;

// レイアウト
$layout-width-inner: 1240px;
// $layout-header-break: 1250px;

// ブレークポイント
$breakpoints: (
    //キー　　値
    "sp": "screen and (max-width: 768px)",
    //767px以下（スマホ）用の表示
    "tab": "(min-width: 769px) and (max-width: #{$layout-width-inner - 1px})",
    // 768px以上（タブレット）用の表示
    "pc": "(min-width: #{$layout-width-inner})",
    //（PC）用の表示
) !default;

// 重なり順の定義
$layer: (
  // ローディング
  loading: 300,
  // モーダル
  modal: 100,
  // ドロワー
  drawer: 40,
  // ヘッダー
  header: 30,
  // フローティング
  floating: 20,
  // フッター
  footer: 10,
  default: 1
);