@use "../global" as global;

.p-top-result {
  &__contents {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    @include global.mq(sp){
      grid-template-columns: repeat(1, 1fr);
      gap: 5.208vw;
    }
  }
  &__btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    margin-top: 60px;
    @include global.mq(sp){
      grid-template-columns: repeat(1, 1fr);
      gap: 6.51vw;
      margin-top: 10.417vw;
    }
    .c-button-bl:nth-child(odd) {
      .c-button-bl__link {
        margin-right: 0;
        @include global.mq(sp){
          margin-right: auto;
        }
      }
    }
    .c-button-bl:not(:first-child) {
      .c-button-bl__link {
        margin-left: 0;
        @include global.mq(sp){
          margin-left: auto;
        }
      }
    }
  }
}
