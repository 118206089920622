@use "../global" as global;

.p-about-know-how{
  padding-top: 88px;
  @include global.mq(sp){
    padding-top: 9.766vw;
  }
  &__contents{
    background-color: #fff;
    border: 4px solid global.$secondary-color;
    padding: 58px 10%;
    position: relative;
    @include global.mq(sp){
      padding: 9.417vw 6.51vw 6.51vw;
      border: 0.78vw solid global.$secondary-color;
    }
  }
  &__ttl{
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &__images{
    margin-top: 40px;
    display: flex;
    @include global.mq(sp){
      margin-top: 5.208vw;
      flex-direction: column;
    }
  }
  &__text{
    margin-top: 40px;
    font-weight: 500;
    line-height: 1.8;
    letter-spacing: 0.06em;
    @include global.mq(sp){
      font-size: 2.6rem;
      margin-top: 5.208vw;
    }
    p:not(:first-child){
      margin-top: 28px;
      @include global.mq(sp){
        margin-top: 5.99vw;
      }
    }
  }
}