@use "../global" as global;

.p-footer-contact{
  overflow: hidden;
  background: url(../img/common/bg_pattern_red.webp) #cd2438;
  background-size: 500px;
  padding: 50px 0 82px;
  text-align: center;
  @include global.mq(sp){
    padding: 6.51vw 0 8.984vw;
    background-size: 65.104vw;
  }
  &__icon{
    width: 60px;
    height: 60px;
    margin: 0 auto;
    @include global.mq(sp){
      width: 7.813vw;
      height: 7.813vw;
    }
  }
  &__lead{
    font-weight: 900;
    font-size: 3.2rem;
    font-size: clamp(2.6rem, 2.199rem + 0.83vw, 3.2rem);
    line-height: 1;
    margin-top: 20px;
    color: #fff;
    @include global.mq(sp){
      font-size: 4rem;
      line-height: 1.2;
      margin-top: 2.604vw;
    }
  }
  &__desc{
    line-height: 1.4;
    letter-spacing: 0.6px;
    font-size: 2.4rem;
    font-size: clamp(2rem, 1.733rem + 0.56vw, 2.4rem);
    font-weight: bold;
    color: #fff;
    margin-top: 40px;
    @include global.mq(sp){
      font-size: 2.4rem;
      margin-top: 3.906vw;
    }
  }
  &__btn{
    margin-top: 40px;
    @include global.mq(sp){
      margin-top: 5.208vw;
    }
  }
}