@use "../global" as global;

.p-company-outline {
  background-color: #fff;
  border: 4px solid global.$secondary-color;
  padding: 45px 60px;
  @include global.mq(sp){
    padding: 6.51vw;
    border: 0.78vw solid global.$secondary-color;
  }
  dt {
    float: left;
    line-height: 1;
    @include global.mq(sp){
      line-height: 1.8;
      font-size: 2.6rem;
    }
  }
  dd {
    margin-left: 190px;
    line-height: 1;
    @include global.mq(sp){
      line-height: 1.8;
      font-size: 2.6rem;
      margin-left: 19.531vw;
    }
  }
  dl:not(:first-child) dd{
    margin-top: 40px;
    @include global.mq(sp){
      margin-top: 3.906vw;
    }
  }
  ol{
    padding-left: 1.3rem;
    @include global.mq(sp){
      padding-left: 2rem;
    }

  }
  li{
    list-style-type: decimal;
    line-height: 1.6;
    @include global.mq(sp){
      line-height: 1.8;
    }
  }
  .txt-ul{
    text-decoration: underline;
  }
}
