@use "../global" as global;

.l-about {
  margin-top: 180px;
  @include global.mq(sp){
    margin-top: 15.625vw;
  }
  &__scheme {
    margin-top: 70px;
    @include global.mq(sp) {
      margin-top: 10.625vw;
    }
  }
  &__anchors{
    margin-top: 158px;
    @include global.mq(sp){
      margin-top: 15.719vw;
    }
  }
  // &__brand{
  //   margin-top: 88px;
  //   @include global.mq(sp){
  //     margin-top: 16.536vw;
  //   }
  // }
  // &__result{
  //   margin-top: 88px;
  //   @include global.mq(sp){
  //     margin-top: 9.766vw;
  //   }
  // }
  // &__know-how{
  //   margin-top: 88px;
  //   @include global.mq(sp){
  //     margin-top: 9.766vw;
  //   }
  // }
}
