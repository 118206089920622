@use "../global" as global;

.p-brand{
  padding: 53px 0 150px;
  @include global.mq(sp){
    padding: 7.813vw 0 15.625vw;
  }
  .p-anchor__list{
    margin-top: 0;
  }
  @include global.mq(sp){
    .p-anchor__item{
      margin: 3.646vw 0 0;
    }
    .p-anchor__item:not(:first-child){
      margin-left: 2.604vw;
    }
    .p-anchor__item:nth-of-type(3){
      margin-left: 0
    }
  }
  .p-product:not(:first-child){
    margin-top: 60px;
    @include global.mq(sp){
      margin-top: 3.906vw;
    }
  }
}