@use "../global" as global;

.l-top {
  &__mv {
    margin-top: 180px;
    @include global.mq(sp) {
      margin-top: 15.625vw;
      margin-top: 19.531vw;
    }
  }
  &__result {
    margin-top: 120px;
    @include global.mq(sp) {
      margin-top: 13.625vw;
    }
  }
  &__bottom-slider{
    margin-top: 150px;
    @include global.mq(sp) {
      margin-top: 15.625vw;
    }
  }
}
