@use "../global" as global;

.c-section-title {
  text-align: center;
  &__en {
    color: global.$primary-color;
    font-size: 1.8rem;
    font-weight: 900;
    line-height: 1;
    margin-bottom: 22px;
    @include global.mq(sp) {
      font-size: 2.4rem;
      margin-bottom: 3.125vw;
    }
  }
  &__text {
    display: inline-block;
    font-size: 3.2rem;
    font-weight: 900;
    line-height: 1;
    padding-bottom: 18px;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-image : linear-gradient(to right, global.$secondary-color 2px, transparent 2px);
      background-size: 4px 4px;
      background-repeat: repeat-x;
      background-position: left bottom;
    }
    @include global.mq(sp) {
      font-size: 4rem;
      padding-bottom: 2.344vw;
      &::after{
        background-image : linear-gradient(to right, global.$secondary-color 0.26vw, transparent 0.26vw);
        background-size: 0.521vw 0.521vw;
      }
    }
  }
}
