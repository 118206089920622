@use "../global" as global;

.p-thanks{
  margin-top: 60px;
  @include global.mq(sp){
    margin-top: 6.51vw;
  }
  &__text{
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
  @include global.mq(sp){
    font-size: 3rem;
  }
  }
  &__text:not(:first-child){
    margin-top: 44px;
    @include global.mq(sp){
      margin-top: 6.25vw;
    }
  }
  &__btn{
    margin-top: 60px;
    @include global.mq(sp){
      margin-top: 13.672vw;
    }
  }
  .c-button-s{
    span{
      &::before{
        content: none;
      }
    }
  }
}