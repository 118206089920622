@use "../global" as global;

.l-result {
  margin-top: 180px;
  @include global.mq(sp){
    margin-top: 15.625vw;
  }
  &__boxes{
    margin-top: 50px;
    @include global.mq(sp) {
      margin-top: 6.51vw;
    }
  }
}