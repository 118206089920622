@use "../global" as global;

.p-result{
  padding: 53px 0 120px;
  @include global.mq(sp){
    padding: 7.813vw 0 15.625vw;
  }
  &__boxes{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    @include global.mq(sp){
      grid-template-columns: repeat(1, 1fr);
      gap: 5.208vw;
    }
  }
  .p-result-box__description{
    margin-top: 26px;
    @include global.mq(sp){
      margin-top: 3.385vw;
    }
  }
}