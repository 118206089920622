@use "../global" as global;

.p-drawer-cta{
  // position: absolute;
  // bottom: 22px;
  // left: 0;
  // right: 0;
  // margin-top: 34.505vw;
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 5.859vw;
  transform: translateX(-50%);
  .c-speech-bubble{
    z-index: 2;
    position: relative;
    &__text {
      font-size: 2.8rem;
      line-height: 1.2;
      padding: 1.823vw 6.4vw;
      // max-width: 67.969vw;
    }
  }
  .c-button{
    max-width: 78.125vw;
    margin: auto;
    &__link{
      font-size: 3.6rem;
      padding: 5.208vw 0 5.729vw;
      &::after{
        width: 1.823vw;
        height: 2.604vw;
        right: 5.99vw;
      }
    }
    span{
      &::before{
        width: 7.031vw;
        height: 7.031vw;
      }
    }
  }
}