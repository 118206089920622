@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap"); /* modern-css-reset を採用*/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html {
  font-size: 62.5%;
}
@media screen and (max-width: 768px) {
  html {
    font-size: 1.3020833333vw;
  }
}

body {
  font-family: "Noto Sans JP", sans-serif;
  line-height: 1.6;
  color: #434343;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  body {
    font-size: 1.6rem;
  }
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  display: inline-block;
}
@media (min-width: 751px) {
  a[href^="tel:"] {
    pointer-events: none;
    cursor: default;
  }
}
li,
dd {
  list-style-type: none;
}

h5,
h6 {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

button {
  border: 0;
  padding: 0;
  margin: 0;
}

.is-pc {
  display: block;
}
@media screen and (max-width: 768px) {
  .is-pc {
    display: none;
  }
}

.is-sp {
  display: none;
}
@media screen and (max-width: 768px) {
  .is-sp {
    display: block;
  }
}

.is-tab {
  display: none;
}
@media screen and (max-width: 950px) {
  .is-tab {
    display: block;
  }
}

/* スクロールすると下からふわっとさせるためのコード */
.fadeUp {
  /* 50px下げる */
  transform: translateY(50px);
  /* 要素を透明にする */
  opacity: 0;
  /* 2秒かけて、変化させる */
  transition: transform 1s, opacity 1s;
}

.fadeUp.show {
  transform: translateY(0);
  opacity: 1;
}

.top-fadeUp {
  animation-name: top-fadeUp;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes top-fadeUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.l-inner {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
@media screen and (max-width: 768px) {
  .l-inner {
    padding: 0 3.906vw;
  }
}

.l-contents {
  background: url(../img/common/bg_pattern.webp) #F2F2F2;
  background-size: 500px;
}
@media screen and (max-width: 768px) {
  .l-contents {
    background-size: 65.104vw;
  }
}

.l-top__mv {
  margin-top: 180px;
}
@media screen and (max-width: 768px) {
  .l-top__mv {
    margin-top: 15.625vw;
    margin-top: 19.531vw;
  }
}
.l-top__result {
  margin-top: 120px;
}
@media screen and (max-width: 768px) {
  .l-top__result {
    margin-top: 13.625vw;
  }
}
.l-top__bottom-slider {
  margin-top: 150px;
}
@media screen and (max-width: 768px) {
  .l-top__bottom-slider {
    margin-top: 15.625vw;
  }
}

.l-about {
  margin-top: 180px;
}
@media screen and (max-width: 768px) {
  .l-about {
    margin-top: 15.625vw;
  }
}
.l-about__scheme {
  margin-top: 70px;
}
@media screen and (max-width: 768px) {
  .l-about__scheme {
    margin-top: 10.625vw;
  }
}
.l-about__anchors {
  margin-top: 158px;
}
@media screen and (max-width: 768px) {
  .l-about__anchors {
    margin-top: 15.719vw;
  }
}

.l-brand {
  margin-top: 180px;
}
@media screen and (max-width: 768px) {
  .l-brand {
    margin-top: 15.625vw;
  }
}
.l-brand__anchors {
  margin-top: 52px;
}
@media screen and (max-width: 768px) {
  .l-brand__anchors {
    margin-top: 3.516vw;
  }
}
.l-brand__products {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .l-brand__products {
    margin-top: 7.813vw;
  }
}

.l-result {
  margin-top: 180px;
}
@media screen and (max-width: 768px) {
  .l-result {
    margin-top: 15.625vw;
  }
}
.l-result__boxes {
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .l-result__boxes {
    margin-top: 6.51vw;
  }
}

.l-company {
  margin-top: 180px;
}
@media screen and (max-width: 768px) {
  .l-company {
    margin-top: 15.625vw;
  }
}
.l-company__outline {
  margin-top: 51px;
}
@media screen and (max-width: 768px) {
  .l-company__outline {
    margin-top: 6.51vw;
  }
}

.l-contact {
  margin-top: 180px;
}
@media screen and (max-width: 768px) {
  .l-contact {
    margin-top: 15.625vw;
  }
}
.l-contact__outline {
  margin-top: 51px;
}
@media screen and (max-width: 768px) {
  .l-contact__outline {
    margin-top: 6.51vw;
  }
}

.l-404 {
  margin-top: 180px;
}
@media screen and (max-width: 768px) {
  .l-404 {
    margin-top: 15.625vw;
  }
}
.l-404__contents {
  margin-top: 100px;
}
@media screen and (max-width: 768px) {
  .l-404__contents {
    margin-top: 6.51vw;
  }
}

.c-section-title {
  text-align: center;
}
.c-section-title__en {
  color: #E5001B;
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 22px;
}
@media screen and (max-width: 768px) {
  .c-section-title__en {
    font-size: 2.4rem;
    margin-bottom: 3.125vw;
  }
}
.c-section-title__text {
  display: inline-block;
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 1;
  padding-bottom: 18px;
  position: relative;
}
.c-section-title__text::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-image: linear-gradient(to right, #F8CE5F 2px, transparent 2px);
  background-size: 4px 4px;
  background-repeat: repeat-x;
  background-position: left bottom;
}
@media screen and (max-width: 768px) {
  .c-section-title__text {
    font-size: 4rem;
    padding-bottom: 2.344vw;
  }
  .c-section-title__text::after {
    background-image: linear-gradient(to right, #F8CE5F 0.26vw, transparent 0.26vw);
    background-size: 0.521vw 0.521vw;
  }
}

.c-button__link {
  max-width: 598px;
  width: 100%;
  margin: 0 auto;
  padding: 44px 0;
  display: block;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1;
  background: #FFE617;
  transform: translateY(-4px);
  box-shadow: 0 8px 0 0 #ffba00;
  border-radius: 16px;
  text-align: center;
  position: relative;
  transition: 0.1s linear;
}
@media screen and (max-width: 768px) {
  .c-button__link {
    max-width: 86.979vw;
    font-size: 4rem;
    box-shadow: 0 1.04vw 0 0 #ffba00;
    border-radius: 2.344vw;
    padding: 5.729vw 0 6.38vw;
  }
}
@media screen and (any-hover: hover) and (min-width: 769px) {
  .c-button__link:hover {
    transform: translateY(4px);
    box-shadow: none;
    opacity: 1;
  }
}
.c-button__link::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 54px;
  width: 13px;
  height: 18px;
  transform: translateY(-50%);
  background: url(../img/common/icon_arrow_contact.svg) no-repeat center center/contain;
}
@media screen and (max-width: 768px) {
  .c-button__link::after {
    width: 2.083vw;
    height: 2.865vw;
    right: 6.771vw;
  }
}
.c-button span {
  padding-left: 30px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .c-button span {
    padding-left: 1.042vw;
  }
}
.c-button span::before {
  position: absolute;
  content: "";
  top: 50%;
  left: -34px;
  width: 50px;
  height: 50px;
  transform: translateY(-50%);
  background: url(../img/common/icon_mail.svg) no-repeat center center/contain;
}
@media screen and (max-width: 768px) {
  .c-button span::before {
    width: 7.813vw;
    height: 7.813vw;
    left: -10.937vw;
  }
}

.c-button-s__link {
  max-width: 270px;
  width: 100%;
  margin: 0 auto;
  padding: 21px 0;
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  background: #FFE617;
  transform: translateY(-2px);
  box-shadow: 0 4px 0 0 #ffba00;
  border-radius: 8px;
  text-align: center;
  position: relative;
  transition: 0.1s linear;
}
@media screen and (max-width: 768px) {
  .c-button-s__link {
    max-width: 55.208vw;
    padding: 3.516vw 0 4.167vw;
    font-size: 2.56rem;
    box-shadow: 0 0.7vw 0 0 #ffba00;
    border-radius: 1.432vw;
  }
}
@media (any-hover: hover) {
  .c-button-s__link:hover {
    opacity: 1;
  }
}
@media screen and (any-hover: hover) and (min-width: 769px) {
  .c-button-s__link:hover {
    transform: translateY(2px);
    box-shadow: none;
  }
}
.c-button-s__link::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 24px;
  width: 6px;
  height: 8px;
  transform: translateY(-50%);
  background: url(../img/common/icon_arrow_contact.svg) no-repeat center center/contain;
}
@media screen and (max-width: 768px) {
  .c-button-s__link::after {
    width: 1.302vw;
    height: 1.823vw;
    right: 4.297vw;
  }
}
.c-button-s span {
  padding-left: 15px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .c-button-s span {
    padding-left: 1.042vw;
  }
}
.c-button-s span::before {
  position: absolute;
  content: "";
  top: 50%;
  left: -14px;
  width: 23px;
  height: 23px;
  transform: translateY(-50%);
  background: url(../img/common/icon_mail.svg) no-repeat center center/contain;
}
@media screen and (max-width: 768px) {
  .c-button-s span::before {
    width: 4.948vw;
    height: 4.948vw;
    left: -7.552vw;
  }
}

.c-button-bl__link {
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
  padding: 23px 0;
  display: block;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
  background: #222222;
  color: #fff;
  border: 2px solid #222222;
  border-radius: 43px;
  text-align: center;
  position: relative;
  transition: 0.1s linear;
}
@media screen and (max-width: 768px) {
  .c-button-bl__link {
    max-width: 79.167vw;
    padding: 4.167vw 0;
    font-size: 2.8rem;
    line-height: 1.6;
    border-radius: 999px;
  }
}
@media (any-hover: hover) {
  .c-button-bl__link:hover {
    opacity: 1;
  }
}
@media screen and (any-hover: hover) and (min-width: 769px) {
  .c-button-bl__link:hover {
    background: #fff;
    color: #222222;
    border: 2px solid #222222;
  }
  .c-button-bl__link:hover::after {
    background-color: #222222;
    right: 18px;
  }
}
.c-button-bl__link::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 28px;
  width: 8px;
  height: 12px;
  transform: translateY(-50%);
  transition: 0.2s linear;
  mask: url(../img/common/icon_arrow.svg) no-repeat center center/contain;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .c-button-bl__link::after {
    width: 1.563vw;
    height: 2.344vw;
    right: 6.51vw;
  }
}
.c-button-bl span {
  padding-right: 4px;
}
@media screen and (max-width: 768px) {
  .c-button-bl span {
    padding-right: 0;
  }
}

.c-speech-bubble {
  text-align: center;
}
.c-speech-bubble__text {
  display: inline-block;
  margin: 0 auto;
  padding: 6px 30px 8px;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.08em;
  background: #E5001B;
  color: #fff;
  border-radius: 43px;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 768px) {
  .c-speech-bubble__text {
    max-width: 79.167vw;
    padding: 1.302vw 6.25vw 1.563vw;
    font-size: 3.5rem;
    line-height: 1.6;
    border-radius: 999px;
    letter-spacing: normal;
  }
}
.c-speech-bubble__text::after {
  position: absolute;
  content: "";
  bottom: -9px;
  left: 50%;
  width: 30px;
  height: 10px;
  transform: translateX(-50%);
  transition: 0.2s linear;
  background: url(../img/common/deco_speech-bubble.svg) no-repeat center center/contain;
}
@media screen and (max-width: 768px) {
  .c-speech-bubble__text::after {
    width: 6.25vw;
    height: 2.083vw;
    bottom: -1.823vw;
  }
}

.p-header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
}
.p-header__content-top {
  height: 100px;
  overflow: hidden;
  background: url(../img/common/bg_pattern_red.webp) #cd2438;
  background-size: 500px;
}
@media screen and (max-width: 768px) {
  .p-header__content-top {
    background: transparent;
    height: 15.885vw;
  }
}
@media screen and (min-width: 769px) {
  .p-header__content-top .p-header__inner {
    transform: translateY(-50px);
    position: relative;
  }
}
@media screen and (max-width: 768px) {
  .p-header__content-top .p-header__inner {
    display: flex;
    align-items: center;
    padding: 4.948vw 15.625vw 2.865vw 3.906vw;
  }
}
.p-header__content-bottom {
  width: 100%;
  padding: 30px 0 31px;
  background: #fff;
}
@media screen and (max-width: 768px) {
  .p-header__content-bottom {
    padding: 3.906vw;
    margin-bottom: 100px;
  }
  .p-header__content-bottom .p-header__inner {
    background-color: #F2F2F2;
    padding: 5.99vw 7.031vw 5.729vw;
    background: url(../img/common/bg_pattern.webp) #F2F2F2;
    background-size: 65.104vw;
    height: calc(100dvh - 7.812vw - 15.625vw);
    position: relative;
  }
}
.p-header__logo {
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .p-header__logo {
    max-width: 33.594vw;
    width: 100%;
  }
  .p-header__logo a {
    display: block;
  }
}
@media screen and (min-width: 769px) {
  .p-header__logo {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background: #fff;
    position: relative;
    vertical-align: middle;
  }
  .p-header__logo img {
    position: absolute;
    width: 140px;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    transition: 0.3s;
  }
}
@media screen and (min-width: 769px) and (any-hover: hover) {
  .p-header__logo img:hover {
    opacity: 0.7;
  }
}
.p-header__catch {
  margin: 0 0 0 30px;
  padding: 0 0 13px;
  border-bottom: 2px dotted #ffdd00;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  color: #fff;
  font-weight: bold;
  font-size: 1.4rem;
  letter-spacing: 2px;
}
@media screen and (max-width: 950px) {
  .p-header__catch {
    line-height: 1.5;
    padding: 0 0 6px;
  }
  .p-header__catch .is-tab {
    line-height: 0;
  }
}
@media screen and (max-width: 790px) {
  .p-header__catch {
    margin-left: 10px;
  }
}
@media screen and (max-width: 768px) {
  .p-header__catch {
    margin: 0 0 0 2.604vw;
    font-size: 2.2rem;
    letter-spacing: normal;
    color: #434343;
    padding: 0;
    border-bottom: none;
    line-height: 1.3;
  }
}
.p-header__btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  max-width: 270px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-header__btn {
    display: none;
  }
}

.p-header__content-bottom.fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.p-mv {
  padding-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .p-mv {
    padding-bottom: 0;
  }
}
.p-mv__inner {
  max-width: 100%;
  margin: 0 50px;
  position: relative;
  height: 600px;
}
@media screen and (max-width: 1700px) {
  .p-mv__inner {
    height: 38vw;
  }
}
@media screen and (max-width: 768px) {
  .p-mv__inner {
    margin: 3.906vw 3.906vw 0;
    height: 120vw;
  }
}
.p-mv__inner::before, .p-mv__inner::after {
  content: "";
  width: 100%;
  height: 300px;
  position: absolute;
}
@media screen and (max-width: 1700px) {
  .p-mv__inner::before, .p-mv__inner::after {
    height: 50%;
  }
}
@media screen and (max-width: 768px) {
  .p-mv__inner::before, .p-mv__inner::after {
    height: 120vw;
    width: 50%;
  }
}
.p-mv__inner::before {
  top: 0;
  left: 0;
  background: url(../img/top/bg_mainvisual_top.webp);
  background-size: 3640px;
  animation: bgLoopLeft 50s linear infinite;
}
@media screen and (max-width: 768px) {
  .p-mv__inner::before {
    background: url(../img/top/bg_mainvisual_top_sp.webp);
    animation: bgLoopTop 20s linear infinite;
    background-size: 100%;
  }
}
.p-mv__inner::after {
  bottom: 0;
  right: 0;
  background: url(../img/top/bg_mainvisual_bottom.webp);
  background-size: 3640px;
  animation: bgLoopRight 50s linear infinite;
}
@media screen and (max-width: 768px) {
  .p-mv__inner::after {
    background: url(../img/top/bg_mainvisual_top_sp.webp);
    animation: bgLoopBottom 20s linear infinite;
    background-size: 100%;
  }
}
@keyframes bgLoopLeft {
  from {
    background-position: 0;
  }
  to {
    background-position: 3640px;
  }
}
@keyframes bgLoopRight {
  from {
    background-position: 0;
  }
  to {
    background-position: -3640px;
  }
}
@keyframes bgLoopTop {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 240vw;
  }
}
@keyframes bgLoopBottom {
  from {
    background-position: 0 240vw;
  }
  to {
    background-position: 0 0;
  }
}
.p-mv__text {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .p-mv__text {
    top: 8.594vw;
  }
}
.p-mv__lead {
  width: 42.7vw;
  max-width: 820px;
}
@media screen and (max-width: 768px) {
  .p-mv__lead {
    width: 72vw;
  }
}
.p-mv__intro {
  max-width: 820px;
}
@media screen and (max-width: 768px) {
  .p-mv__intro {
    max-width: 72.005vw;
  }
}
.p-mv__message {
  width: 56vw;
  max-width: 1080px;
  margin-top: 38px;
}
@media screen and (max-width: 768px) {
  .p-mv__message {
    width: 87vw;
    margin-top: 4.948vw;
  }
}

.p-global-nav {
  text-align: center;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .p-global-nav {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 4.688vw;
  }
}
.p-global-nav__item {
  color: #434343;
  line-height: 1;
}
.p-global-nav__item:not(:first-child) {
  margin: 0 0 0 50px;
}
@media screen and (max-width: 920px) {
  .p-global-nav__item:not(:first-child) {
    margin: 0 0 0 20px;
  }
}
@media screen and (max-width: 768px) {
  .p-global-nav__item:not(:first-child) {
    margin: 0;
  }
}
@media screen and (max-width: 768px) {
  .p-global-nav__item {
    padding: 5.89vw 0;
  }
  .p-global-nav__item a {
    font-size: 3.6rem;
  }
  .p-global-nav__item.is-pc {
    display: none;
  }
}
@media (min-aspect-ratio: 1/1.4) and (max-width: 768px) {
  .p-global-nav__item {
    padding: 4vw 0;
  }
}
.p-global-nav__link {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 2.1px;
  position: relative;
  padding-bottom: 3px;
}
@media screen and (max-width: 768px) {
  .p-global-nav__link {
    padding-bottom: 0;
  }
}
.p-global-nav__link::after {
  content: "";
  width: 0;
  height: 1px;
  max-width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #434343;
  transition: 0.3s;
}
.p-global-nav__link:hover {
  opacity: 1;
}
@media screen and (min-width: 769px) and (any-hover: hover) {
  .p-global-nav__link:hover::after {
    width: 100%;
  }
}

.drawer-hamburger {
  display: none;
}

@media screen and (max-width: 768px) {
  .drawer-hamburger {
    display: block;
    background-color: #CD2438 !important;
    width: 15.625vw;
    height: 15.625vw;
    padding: 0;
    z-index: 40;
  }
  .drawer-hamburger-icon {
    background-color: #fff;
    width: 7.813vw;
    margin: 0 auto;
    height: 0;
  }
  .drawer-hamburger-icon::before, .drawer-hamburger-icon::after {
    background-color: #fff;
  }
  .drawer-hamburger-icon::before {
    top: -1.562vw;
    height: 0.39vw;
  }
  .drawer-hamburger-icon:after {
    top: 1.562vw;
    height: 0.39vw;
  }
  .drawer-nav {
    margin-top: 15.625vw;
    height: 100dvh;
    margin-bottom: 15.625vw;
  }
  .drawer--right .drawer-nav {
    right: -100%;
  }
  .drawer--right.drawer-open .drawer-hamburger {
    right: 0;
  }
  .drawer-overlay {
    background-color: #fff;
  }
}
.p-drawer-cta {
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 5.859vw;
  transform: translateX(-50%);
}
.p-drawer-cta .c-speech-bubble {
  z-index: 2;
  position: relative;
}
.p-drawer-cta .c-speech-bubble__text {
  font-size: 2.8rem;
  line-height: 1.2;
  padding: 1.823vw 6.4vw;
}
.p-drawer-cta .c-button {
  max-width: 78.125vw;
  margin: auto;
}
.p-drawer-cta .c-button__link {
  font-size: 3.6rem;
  padding: 5.208vw 0 5.729vw;
}
.p-drawer-cta .c-button__link::after {
  width: 1.823vw;
  height: 2.604vw;
  right: 5.99vw;
}
.p-drawer-cta .c-button span::before {
  width: 7.031vw;
  height: 7.031vw;
}

@media screen and (max-width: 768px) {
  .p-slider {
    flex-direction: column;
  }
}
.p-slider__wrapper {
  display: flex;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .p-slider__wrapper {
    flex-direction: column;
  }
}
.p-slider__item {
  width: 23.7vw;
  max-width: 455px;
}
@media screen and (max-width: 768px) {
  .p-slider__item {
    width: 100%;
  }
}
@keyframes scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes scroll-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes scroll-top {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes scroll-bottom {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.p-mv__slider-top .p-slider {
  display: flex;
  animation: scroll-right 50s infinite linear 0.5s both;
}
@media screen and (max-width: 768px) {
  .p-mv__slider-top .p-slider {
    animation: scroll-top 50s infinite linear 0.5s both;
  }
}

.p-mv__slider-bottom .p-slider {
  display: flex;
  animation: scroll-left 50s infinite linear 0.5s both;
}
@media screen and (max-width: 768px) {
  .p-mv__slider-bottom .p-slider {
    animation: scroll-bottom 50s infinite linear 0.5s both;
  }
}

.p-intro__inner {
  padding: 0 20px 58px;
  background: #fff;
  max-width: 1200px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-intro__inner {
    padding: 5.854vw 0 5.729vw;
    border-radius: 0 0 4.167vw 4.167vw;
  }
}
.p-intro__heading {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
  .p-intro__heading {
    top: -4.036vw;
  }
}
.p-intro__heading .c-speech-bubble__text {
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .p-intro__heading .c-speech-bubble__text {
    line-height: 1.2;
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 300px) {
  .p-intro__heading .c-speech-bubble__text {
    white-space: normal;
  }
}
.p-intro__message {
  text-align: center;
  font-weight: bold;
  font-size: 3.2rem;
  font-size: clamp(2.4rem, 1.866rem + 1.11vw, 3.2rem);
}
.p-intro__message .txt-important {
  color: #E5001B;
}
@media screen and (max-width: 768px) {
  .p-intro__message {
    font-size: 3rem;
  }
}
.p-intro__btn {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .p-intro__btn {
    margin-top: 3.906vw;
  }
}

.p-top-about {
  padding: 72px 0 0;
}
.p-top-about__suggestion {
  max-width: 1120px;
  width: 100%;
  margin: 20px auto;
}
@media screen and (max-width: 768px) {
  .p-top-about__suggestion {
    margin: 2.6vw auto;
  }
}
.p-top-about__slider {
  margin-top: 80px;
  position: relative;
  height: 428px;
}
@media screen and (max-width: 768px) {
  .p-top-about__slider {
    height: 55.729vw;
    margin-top: 6.51vw;
  }
}
.p-top-about__slider::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../img/top/img_top-about-slider.webp);
  background-size: 1920px;
  animation: aboutLoopRight 30s linear infinite;
}
@media screen and (max-width: 768px) {
  .p-top-about__slider::before {
    background-size: cover;
    animation: aboutLoopRightSp 20s linear infinite;
  }
}
@keyframes aboutLoopRight {
  from {
    background-position: 0;
  }
  to {
    background-position: -1920px;
  }
}
@keyframes aboutLoopRightSp {
  from {
    background-position: 0;
  }
  to {
    background-position: -250vw;
  }
}
.p-top-about__message {
  text-align: center;
  font-weight: bold;
  font-size: 2.8rem;
  font-size: clamp(2.2rem, 1.799rem + 0.83vw, 2.8rem);
  line-height: 1.8;
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  .p-top-about__message {
    font-size: 3.2rem;
    margin-top: 9.115vw;
    line-height: 1.7;
  }
}
.p-top-about__message p:not(:first-child) {
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .p-top-about__message p:not(:first-child) {
    margin-top: 6.51vw;
  }
}
.p-top-about__message .txt-important {
  color: #E5001B;
  font-size: 3.6rem;
  font-size: clamp(2.6rem, 1.932rem + 1.39vw, 3.6rem);
}
@media screen and (max-width: 768px) {
  .p-top-about__message .txt-important {
    font-size: 4.2rem;
  }
}
.p-top-about__description {
  margin-top: 93px;
}
@media screen and (max-width: 768px) {
  .p-top-about__description {
    margin-top: 16.667vw;
  }
}
.p-top-about__qualification {
  margin-top: 58px;
}
@media screen and (max-width: 768px) {
  .p-top-about__qualification {
    margin-top: 9.115vw;
  }
}
.p-top-about__note {
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.06em;
  line-height: 1.8;
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .p-top-about__note {
    font-size: 2.6rem;
    text-align: left;
  }
}
.p-top-about__btn {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .p-top-about__btn {
    margin-top: 5.208vw;
  }
}

.p-top-description {
  padding: 50px;
  border: 4px solid #F8CE5F;
  background-color: #fff;
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-top-description {
    padding: 10.417vw 6.51vw 6.51vw;
    border: 0.78vw solid #F8CE5F;
  }
}
.p-top-description__ttl {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
  .p-top-description__ttl {
    top: -0.911vw;
  }
}
.p-top-description__text {
  line-height: 1.8;
  letter-spacing: 0.06em;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .p-top-description__text {
    font-size: 2.6rem;
    text-align: justify;
  }
}
.p-top-description__text p:not(:first-child) {
  margin-top: 28px;
}
@media screen and (max-width: 768px) {
  .p-top-description__text p:not(:first-child) {
    margin-top: 6.167vw;
  }
}
.p-top-description__image {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-top-description__image {
    margin-top: 8.333vw;
  }
}

.p-top-qualification {
  padding: 48px 4.1%;
  border: 4px solid #F8CE5F;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .p-top-qualification {
    flex-direction: column;
    padding: 10.417vw 6.51vw 6.51vw;
    border: 0.78vw solid #F8CE5F;
  }
}
.p-top-qualification__ttl {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
  .p-top-qualification__ttl {
    top: -0.911vw;
  }
}
.p-top-qualification__text {
  background-color: #fff8e4;
  font-weight: 500;
  padding: 40px 40px;
  padding: 2.083vw 2.083vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .p-top-qualification__text {
    padding: 7.031vw 6.51vw 7.031vw;
  }
}
.p-top-qualification__heading {
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .p-top-qualification__heading {
    font-size: 5.8rem;
  }
}
.p-top-qualification__description {
  margin-top: 20px;
  font-size: 1.3rem;
}
@media screen and (max-width: 768px) {
  .p-top-qualification__description {
    margin-top: 4.167vw;
    font-size: 2.4rem;
  }
}
.p-top-qualification__detail {
  width: 44%;
  width: 100%;
  max-width: 485px;
  margin-left: 2.083vw;
}
@media screen and (max-width: 768px) {
  .p-top-qualification__detail {
    min-width: auto;
    width: 100%;
    margin-left: 0;
    margin-top: 3.906vw;
    max-width: 100%;
  }
}
.p-top-qualification__detail dt {
  font-size: 2.2rem;
  color: #E5001B;
  float: left;
  position: relative;
  padding-left: 48px;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .p-top-qualification__detail dt {
    font-size: 3.8rem;
    padding-left: 9.896vw;
  }
}
.p-top-qualification__detail dt::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 768px) {
  .p-top-qualification__detail dt::before {
    width: 8.333vw;
    height: 8.333vw;
  }
}
.p-top-qualification__detail dt:nth-of-type(1)::before {
  background: url(../img/top/icon_announce.svg) no-repeat center center/contain;
}
.p-top-qualification__detail dt:nth-of-type(2)::before {
  background: url(../img/top/icon_caution.svg) no-repeat center center/contain;
}
.p-top-qualification__detail dd {
  line-height: 1.8;
  font-weight: 500;
  margin-left: 125px;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .p-top-qualification__detail dd {
    margin-left: 26.693vw;
    font-size: 2.6rem;
    margin-bottom: 4.167vw;
  }
}
.p-top-qualification__detail dd span {
  font-size: 1.3rem;
}
@media screen and (max-width: 768px) {
  .p-top-qualification__detail dd span {
    font-size: 2.4rem;
  }
}
.p-top-qualification__detail dd:last-child {
  margin-bottom: 0;
}

.p-top-result__contents {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}
@media screen and (max-width: 768px) {
  .p-top-result__contents {
    grid-template-columns: repeat(1, 1fr);
    gap: 5.208vw;
  }
}
.p-top-result__btns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .p-top-result__btns {
    grid-template-columns: repeat(1, 1fr);
    gap: 6.51vw;
    margin-top: 10.417vw;
  }
}
.p-top-result__btns .c-button-bl:nth-child(odd) .c-button-bl__link {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  .p-top-result__btns .c-button-bl:nth-child(odd) .c-button-bl__link {
    margin-right: auto;
  }
}
.p-top-result__btns .c-button-bl:not(:first-child) .c-button-bl__link {
  margin-left: 0;
}
@media screen and (max-width: 768px) {
  .p-top-result__btns .c-button-bl:not(:first-child) .c-button-bl__link {
    margin-left: auto;
  }
}

.p-result-box {
  border-radius: 14px;
  overflow: hidden;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .p-result-box {
    border-radius: 1.823vw;
  }
}
.p-result-box__body {
  padding: 20px 40px 50px;
  padding: 1.042vw 2.083vw 2.604vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .p-result-box__body {
    padding: 3.906vw 6.51vw 4.51vw;
  }
}
.p-result-box__logo {
  max-width: 308px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-result-box__logo {
    max-width: 64.063vw;
  }
}
.p-result-box__earnings {
  line-height: 1;
  font-weight: bold;
  font-size: 3rem;
  font-size: clamp(2.6rem, 2.333rem + 0.56vw, 3rem);
  letter-spacing: 0.08em;
  margin-top: 10px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .p-result-box__earnings {
    font-size: 4.6rem;
    margin-top: 2vw;
    letter-spacing: normal;
  }
}
.p-result-box__earnings .txt-important {
  color: #E5001B;
}
@media screen and (max-width: 768px) {
  .p-result-box__earnings .is-tab {
    display: none;
  }
}
.p-result-box .num {
  font-size: 6rem;
  font-size: clamp(5rem, 4.332rem + 1.39vw, 6rem);
  letter-spacing: normal;
}
@media screen and (max-width: 768px) {
  .p-result-box .num {
    font-size: 9.3rem;
  }
}
.p-result-box .span {
  font-size: 1.8rem;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 768px) {
  .p-result-box .span {
    font-size: 2.8rem;
  }
}
.p-result-box__description {
  margin-top: 21px;
  font-weight: 500;
  color: #8D8D8D;
}
@media screen and (max-width: 768px) {
  .p-result-box__description {
    font-size: 2rem;
    margin-top: 3.646vw;
  }
}

.p-top-bottom-slider {
  height: 115px;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-top-bottom-slider {
    height: 14.974vw;
  }
}
.p-top-bottom-slider::before {
  content: "";
  height: 115px;
  position: absolute;
  width: 100%;
  animation: bgBottom 30s linear infinite;
  background: url(../img/top/img_slider_footer.webp);
  background-size: 1395px;
  left: 0;
  top: 0;
}
@media screen and (max-width: 768px) {
  .p-top-bottom-slider::before {
    height: 14.974vw;
    background-size: 181.641vw;
    animation: bgBottomSp 30s linear infinite;
  }
}
@keyframes bgBottom {
  from {
    background-position: 0;
  }
  to {
    background-position: -1395px;
    @media screen and (max-width: 768px) {
      .p-top-bottom-slider {
        background-position: -181.641vw;
      }
    }
  }
}
@keyframes bgBottomSp {
  from {
    background-position: 0;
  }
  to {
    background-position: -181.641vw;
  }
}

.p-footer-contact {
  overflow: hidden;
  background: url(../img/common/bg_pattern_red.webp) #cd2438;
  background-size: 500px;
  padding: 50px 0 82px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .p-footer-contact {
    padding: 6.51vw 0 8.984vw;
    background-size: 65.104vw;
  }
}
.p-footer-contact__icon {
  width: 60px;
  height: 60px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .p-footer-contact__icon {
    width: 7.813vw;
    height: 7.813vw;
  }
}
.p-footer-contact__lead {
  font-weight: 900;
  font-size: 3.2rem;
  font-size: clamp(2.6rem, 2.199rem + 0.83vw, 3.2rem);
  line-height: 1;
  margin-top: 20px;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .p-footer-contact__lead {
    font-size: 4rem;
    line-height: 1.2;
    margin-top: 2.604vw;
  }
}
.p-footer-contact__desc {
  line-height: 1.4;
  letter-spacing: 0.6px;
  font-size: 2.4rem;
  font-size: clamp(2rem, 1.733rem + 0.56vw, 2.4rem);
  font-weight: bold;
  color: #fff;
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-footer-contact__desc {
    font-size: 2.4rem;
    margin-top: 3.906vw;
  }
}
.p-footer-contact__btn {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-footer-contact__btn {
    margin-top: 5.208vw;
  }
}

.p-footer {
  padding: 74px 0 102px;
}
@media screen and (max-width: 768px) {
  .p-footer {
    padding: 3.906vw 0 23.438vw;
  }
}
.p-footer__inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .p-footer__inner {
    flex-direction: column-reverse;
    padding: 0 32px;
  }
}
@media screen and (max-width: 768px) {
  .p-footer__inner {
    padding: 0 7.813vw;
  }
}
.p-footer__company {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .p-footer__company {
    margin-top: 14.323vw;
  }
}
.p-footer__logo a {
  max-width: 170px;
  width: 100%;
  transition: 0.3s;
}
@media screen and (max-width: 768px) {
  .p-footer__logo a {
    max-width: 45.573vw;
  }
}
@media (any-hover: hover) {
  .p-footer__logo a:hover {
    opacity: 0.7;
  }
}
.p-footer__text {
  font-size: 1.2rem;
  line-height: 1;
  margin-top: 16px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .p-footer__text {
    font-size: 2.4rem;
    margin-top: 4.604vw;
  }
}
.p-footer__contents {
  text-align: right;
  margin-left: 40px;
}
@media screen and (max-width: 1200px) {
  .p-footer__contents {
    margin-left: 0;
  }
}
.p-footer__nav-list {
  display: flex;
  margin-top: -30px;
}
@media screen and (max-width: 1200px) {
  .p-footer__nav-list {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .p-footer__nav-list {
    margin-top: 0;
    margin-left: -7.813vw;
  }
}
.p-footer__nav-item {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 2.1px;
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .p-footer__nav-item {
    margin-top: 7.313vw;
    font-size: 3.2rem;
    margin-left: 7.813vw;
  }
}
.p-footer__nav-item:not(:first-child) {
  margin-left: 50px;
}
@media screen and (max-width: 1300px) {
  .p-footer__nav-item:not(:first-child) {
    margin-left: 40px;
  }
}
@media screen and (max-width: 880px) {
  .p-footer__nav-item:not(:first-child) {
    margin-left: 20px;
  }
}
@media screen and (max-width: 800px) {
  .p-footer__nav-item:not(:first-child) {
    margin-left: 15px;
  }
}
@media screen and (max-width: 768px) {
  .p-footer__nav-item:not(:first-child) {
    margin-left: 6.51vw;
  }
}
.p-footer__nav-link {
  position: relative;
  padding-bottom: 3px;
}
.p-footer__nav-link::after {
  content: "";
  width: 0;
  height: 1px;
  max-width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #434343;
  transition: 0.3s;
}
.p-footer__nav-link:hover {
  opacity: 1;
}
@media screen and (min-width: 769px) and (any-hover: hover) {
  .p-footer__nav-link:hover::after {
    width: 100%;
  }
}
.p-footer__copyright {
  font-size: 1.2rem;
  line-height: 1;
  margin-top: 43px;
}
@media screen and (max-width: 1200px) {
  .p-footer__copyright {
    text-align: center;
    margin-bottom: 10.417vw;
  }
}
@media screen and (max-width: 768px) {
  .p-footer__copyright {
    display: none;
  }
}
.p-footer.contact-footer {
  padding-bottom: 22px;
}
@media screen and (max-width: 768px) {
  .p-footer.contact-footer {
    padding-bottom: 16vw;
  }
}

.p-footer-cta {
  background-color: #fff;
  padding: 11px 0;
  width: 100%;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 1;
  pointer-events: none; /* 非表示時のクリックイベントを無効化 */
}
@media screen and (max-width: 768px) {
  .p-footer-cta {
    padding: 2.214vw 0;
  }
}
.p-footer-cta.visible {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto; /* 表示時のクリックイベントを有効化 */
}

.p-about {
  padding: 53px 0 150px;
}
@media screen and (max-width: 768px) {
  .p-about {
    padding: 7.813vw 0 15.625vw;
  }
}
@media screen and (max-width: 768px) {
  .p-about .p-top-about__suggestion {
    margin: 4.6vw auto 1vw;
  }
}
.p-about .p-about__heading {
  font-weight: bold;
  font-size: 2.8rem;
  letter-spacing: 0.02em;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .p-about .p-about__heading {
    font-size: 4rem;
    line-height: 1.4;
    letter-spacing: normal;
  }
}
@media screen and (max-width: 1030px) {
  .p-about .p-about__heading .is-tab {
    display: block;
  }
}

.p-about-scheme__ttl {
  text-align: center;
}
.p-about-scheme__ttl h2 {
  font-size: 5rem;
  font-weight: bold;
  display: inline-block;
  position: relative;
  line-height: 1;
  padding-top: 34px;
  padding-left: 76px;
  padding-right: 76px;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__ttl h2 {
    font-size: 5rem;
    line-height: 1.2;
    padding-top: 1.302vw;
    padding-left: 14.844vw;
    padding-right: 14.844vw;
  }
}
.p-about-scheme__ttl h2::before, .p-about-scheme__ttl h2::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
  height: 80px;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__ttl h2::before, .p-about-scheme__ttl h2::after {
    width: 10.417vw;
    height: 10.417vw;
    top: 30%;
  }
}
.p-about-scheme__ttl h2::before {
  left: 0;
  background: url(../img/about/icon_quote_left.svg) no-repeat center/contain;
}
.p-about-scheme__ttl h2::after {
  right: 0;
  background: url(../img/about/icon_quote_right.svg) no-repeat center/contain;
}
.p-about-scheme__ttl h2 .is-br {
  display: none;
}
@media screen and (max-width: 999px) {
  .p-about-scheme__ttl h2 .is-br {
    display: block;
    line-height: 0;
  }
}
.p-about-scheme__contents {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__contents {
    margin-top: 5.208vw;
  }
}
.p-about-scheme__box {
  background-color: #fff;
  border: 4px solid #F8CE5F;
  padding: 40px 10% 60px;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__box {
    padding: 5.208vw 6.51vw;
    border: 0.78vw solid #F8CE5F;
  }
}
.p-about-scheme__box:nth-of-type(2) {
  margin-top: 26px;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__box:nth-of-type(2) {
    margin-top: 3.906vw;
  }
}
.p-about-scheme__heading {
  letter-spacing: 0.02em;
  font-weight: 900;
  font-size: 3.6rem;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__heading {
    font-size: 4rem;
    line-height: 1.4;
  }
}
.p-about-scheme__heading .txt-important {
  color: #E5001B;
}
.p-about-scheme__heading .small {
  font-size: 2.4rem;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__heading .small {
    font-size: 3.2rem;
  }
}
.p-about-scheme__heading .is-br {
  display: none;
}
@media screen and (max-width: 870px) {
  .p-about-scheme__heading .is-br {
    display: block;
  }
}
.p-about-scheme__image {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__image {
    margin-top: 3.906vw;
  }
}
.p-about-scheme__text {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__text {
    font-size: 2.6rem;
    line-height: 1.8;
    margin-top: 3.906vw;
  }
}
.p-about-scheme__text p {
  line-height: 1.8;
  letter-spacing: 0.06em;
  font-weight: 500;
}
.p-about-scheme__text p:not(:first-child) {
  margin-top: 24px;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__text p:not(:first-child) {
    margin-top: 5.208vw;
  }
}
.p-about-scheme__note {
  text-align: center;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.4;
  letter-spacing: 0.6px;
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__note {
    font-size: 3rem;
    line-height: 1.6;
    letter-spacing: normal;
    margin-top: 8.813vw;
  }
}
.p-about-scheme__note .is-br {
  display: none;
}
@media screen and (max-width: 880px) {
  .p-about-scheme__note .is-br {
    display: block;
  }
}
.p-about-scheme__btn {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-about-scheme__btn {
    margin-top: 3.646vw;
  }
}

.p-anchor__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -30px;
}
@media screen and (max-width: 768px) {
  .p-anchor__list {
    margin-top: -3.646vw;
  }
}
.p-anchor__item {
  text-align: center;
  max-width: 320px;
  width: 100%;
  margin: 30px 15px 0;
}
@media screen and (max-width: 768px) {
  .p-anchor__item {
    max-width: fit-content;
    margin: 3.646vw 1.823vw 0;
  }
}
.p-anchor__link {
  line-height: 1;
  display: inline-block;
  font-size: 2.2rem;
  font-weight: bold;
  padding: 24px 0;
  width: 100%;
  position: relative;
  transition: 0.1s linear;
  transform: translateY(-2px);
  box-shadow: 0 4px 0 0 #cdcdcd;
  background-color: #fff;
  border-radius: 999px;
}
@media screen and (max-width: 768px) {
  .p-anchor__link {
    font-size: 3.4rem;
    padding: 3.385vw 11.979vw 4.427vw 6.25vw;
    box-shadow: 0 0.78vw 0 0 #cdcdcd;
  }
}
.p-anchor__link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  width: 26px;
  height: 26px;
  background: url(../img/brand/icon_anchor.svg) no-repeat center/contain;
}
@media screen and (max-width: 768px) {
  .p-anchor__link::after {
    width: 5.469vw;
    height: 5.469vw;
    right: 3.906vw;
  }
}
@media screen and (any-hover: hover) and (min-width: 769px) {
  .p-anchor__link:hover {
    box-shadow: none;
    transform: translateY(2px);
  }
}
.p-anchor__link span {
  margin-right: 20px;
}
@media screen and (max-width: 768px) {
  .p-anchor__link span {
    margin-right: 0;
  }
}

.p-about-brand {
  padding-top: 88px;
}
@media screen and (max-width: 768px) {
  .p-about-brand {
    padding-top: 16.536vw;
  }
}
.p-about-brand__contents {
  background-color: #fff;
  border: 4px solid #F8CE5F;
  padding: 58px 10%;
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-about-brand__contents {
    padding: 9.417vw 6.51vw 6.51vw;
    border: 0.78vw solid #F8CE5F;
  }
}
.p-about-brand__ttl {
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.p-about-brand__images {
  margin-top: 40px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .p-about-brand__images {
    margin-top: 5.208vw;
    flex-direction: column;
  }
}
.p-about-brand__text {
  margin-top: 40px;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 768px) {
  .p-about-brand__text {
    font-size: 2.6rem;
    margin-top: 5.208vw;
  }
}
.p-about-brand__text p:not(:first-child) {
  margin-top: 28px;
}
@media screen and (max-width: 768px) {
  .p-about-brand__text p:not(:first-child) {
    margin-top: 5.99vw;
  }
}
.p-about-brand__btn {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-about-brand__btn {
    margin-top: 5.208vw;
  }
}

.p-about-result {
  padding-top: 88px;
}
@media screen and (max-width: 768px) {
  .p-about-result {
    padding-top: 9.766vw;
  }
}
.p-about-result__contents {
  background-color: #fff;
  border: 4px solid #F8CE5F;
  padding: 58px 10%;
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-about-result__contents {
    padding: 9.417vw 6.51vw 6.51vw;
    border: 0.78vw solid #F8CE5F;
  }
}
.p-about-result__ttl {
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
  .p-about-result__ttl {
    top: -1.562vw;
  }
}
.p-about-result__images {
  margin-top: 40px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .p-about-result__images {
    margin-top: 5.208vw;
    flex-direction: column;
  }
}
.p-about-result__text {
  margin-top: 40px;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 768px) {
  .p-about-result__text {
    font-size: 2.6rem;
    margin-top: 5.208vw;
  }
}
.p-about-result__text p:not(:first-child) {
  margin-top: 28px;
}
@media screen and (max-width: 768px) {
  .p-about-result__text p:not(:first-child) {
    margin-top: 5.99vw;
  }
}
.p-about-result__btn {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-about-result__btn {
    margin-top: 5.208vw;
  }
}

.p-about-know-how {
  padding-top: 88px;
}
@media screen and (max-width: 768px) {
  .p-about-know-how {
    padding-top: 9.766vw;
  }
}
.p-about-know-how__contents {
  background-color: #fff;
  border: 4px solid #F8CE5F;
  padding: 58px 10%;
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-about-know-how__contents {
    padding: 9.417vw 6.51vw 6.51vw;
    border: 0.78vw solid #F8CE5F;
  }
}
.p-about-know-how__ttl {
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.p-about-know-how__images {
  margin-top: 40px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .p-about-know-how__images {
    margin-top: 5.208vw;
    flex-direction: column;
  }
}
.p-about-know-how__text {
  margin-top: 40px;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 768px) {
  .p-about-know-how__text {
    font-size: 2.6rem;
    margin-top: 5.208vw;
  }
}
.p-about-know-how__text p:not(:first-child) {
  margin-top: 28px;
}
@media screen and (max-width: 768px) {
  .p-about-know-how__text p:not(:first-child) {
    margin-top: 5.99vw;
  }
}

.p-brand {
  padding: 53px 0 150px;
}
@media screen and (max-width: 768px) {
  .p-brand {
    padding: 7.813vw 0 15.625vw;
  }
}
.p-brand .p-anchor__list {
  margin-top: 0;
}
@media screen and (max-width: 768px) {
  .p-brand .p-anchor__item {
    margin: 3.646vw 0 0;
  }
  .p-brand .p-anchor__item:not(:first-child) {
    margin-left: 2.604vw;
  }
  .p-brand .p-anchor__item:nth-of-type(3) {
    margin-left: 0;
  }
}
.p-brand .p-product:not(:first-child) {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .p-brand .p-product:not(:first-child) {
    margin-top: 3.906vw;
  }
}

.p-product {
  border-radius: 14px;
  overflow: hidden;
}
.p-product__head {
  display: flex;
}
.p-product__body {
  background-color: #fff;
  padding: 60px 10%;
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-product__body {
    padding: 7.813vw 6.51vw;
  }
}
.p-product__logo {
  padding: 20px 60px;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 40px 40px 0 0;
  position: absolute;
  top: -94px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 428px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-product__logo {
    padding: 2.604vw 7.813vw;
    max-width: 55.729vw;
    top: -12vw;
    border-radius: 5.208vw 5.208vw 0 0;
  }
}
.p-product__lead {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 768px) {
  .p-product__lead {
    font-size: 2.6rem;
    line-height: 1.8;
  }
}
.p-product__contents {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-product__contents {
    margin-top: 5.208vw;
  }
}
.p-product__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px 30px;
}
@media screen and (max-width: 768px) {
  .p-product__list {
    grid-template-columns: repeat(1, 1fr);
    gap: 6.51vw;
  }
}
.p-product__item {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 3;
  gap: 0;
}
.p-product__name {
  text-align: center;
  font-size: 2.4rem;
  line-height: 1;
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .p-product__name {
    font-size: 4rem;
    margin-top: 3.906vw;
  }
}
.p-product__text {
  margin-top: 14px;
  padding: 20px;
  font-size: 1.5rem;
  font-weight: 500;
  background-color: #f4f4f4;
  border-radius: 0 20px 0 20px;
}
@media screen and (max-width: 768px) {
  .p-product__text {
    font-size: 2.4rem;
    margin-top: 3.906vw;
    padding: 2.604vw;
    border-radius: 0 2.604vw 0 2.604vw;
  }
}

#torte .p-product__item {
  grid-row: span 1;
}

.p-result {
  padding: 53px 0 120px;
}
@media screen and (max-width: 768px) {
  .p-result {
    padding: 7.813vw 0 15.625vw;
  }
}
.p-result__boxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}
@media screen and (max-width: 768px) {
  .p-result__boxes {
    grid-template-columns: repeat(1, 1fr);
    gap: 5.208vw;
  }
}
.p-result .p-result-box__description {
  margin-top: 26px;
}
@media screen and (max-width: 768px) {
  .p-result .p-result-box__description {
    margin-top: 3.385vw;
  }
}

.p-company {
  padding: 53px 0 120px;
}
@media screen and (max-width: 768px) {
  .p-company {
    padding: 7.813vw 0 15.625vw;
  }
}

.p-company-outline {
  background-color: #fff;
  border: 4px solid #F8CE5F;
  padding: 45px 60px;
}
@media screen and (max-width: 768px) {
  .p-company-outline {
    padding: 6.51vw;
    border: 0.78vw solid #F8CE5F;
  }
}
.p-company-outline dt {
  float: left;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .p-company-outline dt {
    line-height: 1.8;
    font-size: 2.6rem;
  }
}
.p-company-outline dd {
  margin-left: 190px;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .p-company-outline dd {
    line-height: 1.8;
    font-size: 2.6rem;
    margin-left: 19.531vw;
  }
}
.p-company-outline dl:not(:first-child) dd {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .p-company-outline dl:not(:first-child) dd {
    margin-top: 3.906vw;
  }
}
.p-company-outline ol {
  padding-left: 1.3rem;
}
@media screen and (max-width: 768px) {
  .p-company-outline ol {
    padding-left: 2rem;
  }
}
.p-company-outline li {
  list-style-type: decimal;
  line-height: 1.6;
}
@media screen and (max-width: 768px) {
  .p-company-outline li {
    line-height: 1.8;
  }
}
.p-company-outline .txt-ul {
  text-decoration: underline;
}

.p-contact {
  padding: 53px 0 120px;
}
@media screen and (max-width: 768px) {
  .p-contact {
    padding: 7.813vw 0 8.333vw;
  }
}
.p-contact__lead {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  margin-top: 51px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .p-contact__lead {
    font-size: 3rem;
    line-height: 1.6;
    margin-top: 6.51vw;
  }
}
.p-contact__form {
  margin-top: 40px;
  border-radius: 14px;
}
@media screen and (max-width: 768px) {
  .p-contact__form {
    margin-top: 8.073vw;
  }
}
.p-contact .form {
  padding: 0 0 62px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 14px 14px;
}
@media screen and (max-width: 768px) {
  .p-contact .form {
    padding: 0 0 8.333vw;
  }
}
.p-contact .customer {
  background-color: #fffce3;
}
.p-contact .title {
  padding: 15px 0;
  color: #fff;
  background-color: #454643;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-align: center;
  border-radius: 14px 14px 0 0;
}
@media screen and (max-width: 768px) {
  .p-contact .title {
    font-size: 2.6rem;
    padding: 1.823vw 0 1.953vw;
  }
}
.p-contact .form_item {
  border-bottom: 1px solid #dadada;
  position: relative;
  display: flex;
}
@media screen and (max-width: 768px) {
  .p-contact .form_item {
    flex-direction: column;
  }
}
.p-contact .form_item::after {
  content: "";
  display: block;
  clear: both;
}
.p-contact dt {
  max-width: 40%;
  width: 100%;
  padding: 28px 30px;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-contact dt {
    max-width: 100%;
    padding: 2.1vw 3.906vw 2.1vw;
  }
}
.p-contact dt label {
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .p-contact dt label {
    font-size: 2.6rem;
    line-height: 1.8;
    letter-spacing: 0.06em;
  }
}
.p-contact .dt_required {
  padding: 2px 5px 3px;
  display: inline-block;
  color: #e5004f;
  font-size: 1.4rem;
  line-height: 1.4;
  border: 1px solid #e5004f;
  position: absolute;
  right: 20px;
  top: 30px;
}
@media screen and (max-width: 768px) {
  .p-contact .dt_required {
    position: static;
    margin-left: 1.302vw;
    font-size: 2.2rem;
    line-height: 1;
    padding: 0.4vw 1.042vw 0.7vw;
  }
}
.p-contact dd {
  padding: 20px;
  max-width: 60%;
  width: 100%;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .p-contact dd {
    max-width: 100%;
    margin-left: 0;
    padding: 3.906vw;
  }
}
.p-contact input[type=text] {
  padding: 8px 12px;
  color: #434343;
  background-color: #f8f8f8;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  border: 1px solid #dadada;
  border-radius: 4px;
  outline: none;
  max-width: 200px;
}
@media screen and (max-width: 768px) {
  .p-contact input[type=text] {
    font-size: 2.8rem;
    padding: 3.604vw 3vw;
    max-width: 100%;
    width: 100%;
  }
}
.p-contact input[type=text]::placeholder {
  color: #c3c3c3;
}
.p-contact input[type=text].input-long {
  max-width: 550px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-contact input[type=text].input-long {
    max-width: 100%;
  }
}
.p-contact textarea {
  max-width: 550px;
  width: 100%;
  height: 350px;
  padding: 8px 12px;
  color: #434343;
  background-color: #f8f8f8;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  border: 1px solid #dadada;
  border-radius: 4px;
  outline: none;
}
@media screen and (max-width: 768px) {
  .p-contact textarea {
    font-size: 2.8rem;
    padding: 2.604vw 3.255vw;
    max-width: 100%;
    height: 32.552vw;
  }
}
.p-contact textarea::placeholder {
  color: #c3c3c3;
}
.p-contact .submit-wrapper {
  text-align: center;
  padding: 40px 0 0;
  background: #fff;
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-contact .submit-wrapper {
    padding-top: 7.813vw;
  }
}
.p-contact .submit {
  position: relative;
  display: inline-block;
  max-width: 598px;
  width: 100%;
  margin: 0 auto;
  display: block;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1;
  background: #FFE617;
  transform: translateY(-4px);
  box-shadow: 0 8px 0 0 #ffba00;
  border-radius: 16px;
  text-align: center;
  position: relative;
  transition: 0.1s linear;
}
@media screen and (max-width: 768px) {
  .p-contact .submit {
    max-width: 78.125vw;
    font-size: 3.8rem;
    box-shadow: 0 1.04vw 0 0 #ffba00;
    border-radius: 2.344vw;
  }
}
@media screen and (any-hover: hover) and (min-width: 769px) {
  .p-contact .submit:hover {
    transform: translateY(4px);
    box-shadow: none;
    opacity: 1;
  }
}
.p-contact .submit::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 54px;
  width: 13px;
  height: 18px;
  transform: translateY(-50%);
  background: url(../img/common/icon_arrow_contact.svg) no-repeat center center/contain;
}
@media screen and (max-width: 768px) {
  .p-contact .submit::after {
    width: 2.083vw;
    height: 2.865vw;
    right: 6.771vw;
  }
}
.p-contact input[type=submit] {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #434343;
  width: 100%;
  padding: 44px 0;
}
@media screen and (max-width: 768px) {
  .p-contact input[type=submit] {
    padding: 5vw 2.865vw 5.7vw 0;
  }
}
.p-contact .contact__confirm {
  text-align: center;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.06em;
  background-color: #fff;
  padding: 32px 0 28px;
}
@media screen and (max-width: 768px) {
  .p-contact .contact__confirm {
    font-size: 2.6rem;
    line-height: 1.8;
    padding: 6.51vw 0;
  }
}
.p-contact .contact__confirm label {
  margin-left: 4px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .p-contact .contact__confirm label {
    margin-left: 10px;
  }
}
.p-contact input[type=checkbox] {
  position: relative;
  width: 22px;
  height: 22px;
  border: 1px solid #dadada;
  background-color: #f8f8f8;
  border-radius: 4px;
  vertical-align: -8px;
  appearance: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .p-contact input[type=checkbox] {
    width: 4.688vw;
    height: 4.688vw;
    vertical-align: -1.172vw;
    margin: 2px 0 0 0;
  }
}
.p-contact input[type=checkbox]:checked:before {
  border-bottom: 3px solid #e5004f;
  border-right: 3px solid #e5004f;
  content: "";
  height: 14px;
  left: 7px;
  position: absolute;
  top: 1px;
  transform: rotate(50deg);
  width: 8px;
}
@media screen and (max-width: 768px) {
  .p-contact input[type=checkbox]:checked:before {
    height: 2.865vw;
    width: 1.563vw;
    top: 0.391vw;
    left: 1.563vw;
    border-bottom: 0.52vw solid #e5004f;
    border-right: 0.52vw solid #e5004f;
  }
}
.p-contact .input-text {
  margin: 19px 0 24px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.8px;
}
@media screen and (max-width: 768px) {
  .p-contact .input-text {
    font-size: 2.6rem;
    line-height: 1.8;
    margin: 1.804vw 0;
  }
}
.p-contact__privacy {
  background-color: #fff;
  padding: 0 20px;
}
@media screen and (max-width: 768px) {
  .p-contact__privacy {
    padding: 0 3.906vw;
  }
}
.p-contact .has_error {
  margin: 10px 0 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8;
  color: red;
  display: block;
}
@media screen and (max-width: 768px) {
  .p-contact .has_error {
    font-size: 2.6rem;
  }
}
.p-contact .em_privacy {
  text-align: center;
  padding-bottom: 20px;
  margin-top: -20px;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .p-contact .is-tab {
    display: none;
  }
}

#confirm .p-contact dd {
  padding: 28px 20px;
}
@media screen and (max-width: 768px) {
  #confirm .p-contact dd {
    font-size: 2.6rem;
    line-height: 1.8;
    padding: 3.906vw;
    font-weight: 500;
  }
}

@media screen and (max-width: 768px) {
  #thanks .p-contact {
    padding: 7.813vw 0 15.625vw;
  }
}

.p-privacy-policy {
  max-width: 960px;
  margin: 0 auto;
  height: 112px;
  overflow-y: scroll;
  padding: 20px;
  border: 1px solid #dadada;
  line-height: 1.5;
  border-radius: 4px;
  scrollbar-width: 10px;
  scrollbar-color: #dadada;
}
@media screen and (max-width: 768px) {
  .p-privacy-policy {
    padding: 3.906vw 5.208vw 3.906vw 3.906vw;
    height: 39.063vw;
  }
}
.p-privacy-policy p {
  font-size: 1.2rem;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .p-privacy-policy p {
    font-size: 2.2rem;
    line-height: 2;
    letter-spacing: 0.06em;
  }
}
.p-privacy-policy p:not(:first-child) {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .p-privacy-policy p:not(:first-child) {
    margin-top: 4.688vw;
  }
}
.p-privacy-policy::-webkit-scrollbar {
  width: 10px;
  background-color: #f8f8f8;
}
@media screen and (max-width: 768px) {
  .p-privacy-policy::-webkit-scrollbar {
    width: 2.604vw;
  }
}
.p-privacy-policy::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 6px;
}
@media screen and (max-width: 768px) {
  .p-privacy-policy::-webkit-scrollbar-thumb {
    border-radius: 1.563vw;
  }
}
@media screen and (max-width: 768px) {
  .p-privacy-policy {
    scrollbar-width: 2.604vw;
  }
}

.p-confirm-btn {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px 0;
}
@media screen and (max-width: 768px) {
  .p-confirm-btn {
    flex-direction: column;
    padding: 50px 20px 0;
  }
}
.p-confirm-btn__return {
  max-width: 270px;
  width: 100%;
  margin: 0 auto;
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  background: #FFE617;
  transform: translateY(-2px);
  box-shadow: 0 4px 0 0 #ffba00;
  border-radius: 8px;
  text-align: center;
  position: relative;
  transition: 0.1s linear;
  height: fit-content;
}
.p-confirm-btn__return [type=button] {
  background-color: transparent;
  border: none;
  color: #434343;
  cursor: pointer;
  width: 100%;
  padding: 21px 0;
}
@media screen and (max-width: 768px) {
  .p-confirm-btn__return [type=button] {
    padding: 4.167vw 0 4.427vw;
  }
}
@media screen and (max-width: 768px) {
  .p-confirm-btn__return {
    font-size: 2.4rem;
    max-width: 58.594vw;
    box-shadow: 0 0.7vw 0 0 #ffba00;
    border-radius: 1.432vw;
  }
}
@media screen and (any-hover: hover) and (min-width: 769px) {
  .p-confirm-btn__return:hover {
    transform: translateY(2px);
    box-shadow: none;
    opacity: 1;
  }
}
.p-confirm-btn__return::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 24px;
  width: 6px;
  height: 8px;
  transform: translateY(-50%);
  background: url(../img/contact/icon_arrow_return.svg) no-repeat center center/contain;
}
@media screen and (max-width: 768px) {
  .p-confirm-btn__return::after {
    width: 1.302vw;
    height: 1.823vw;
    right: 4.297vw;
  }
}
.p-confirm-btn__submit {
  position: relative;
  display: inline-block;
  max-width: 598px;
  width: 100%;
  margin: 0 auto;
  margin-left: 40px;
  display: block;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1;
  background: #FFE617;
  transform: translateY(-4px);
  box-shadow: 0 8px 0 0 #ffba00;
  border-radius: 16px;
  text-align: center;
  position: relative;
  transition: 0.1s linear;
}
@media screen and (max-width: 768px) {
  .p-confirm-btn__submit {
    max-width: 78.125vw;
    font-size: 3.8rem;
    box-shadow: 0 1.04vw 0 0 #ffba00;
    border-radius: 2.344vw;
    margin-top: 7.813vw;
    margin-left: auto;
  }
}
@media screen and (any-hover: hover) and (min-width: 769px) {
  .p-confirm-btn__submit:hover {
    transform: translateY(4px);
    box-shadow: none;
    opacity: 1;
  }
}
.p-confirm-btn__submit::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 54px;
  width: 13px;
  height: 18px;
  transform: translateY(-50%);
  background: url(../img/common/icon_arrow_contact.svg) no-repeat center center/contain;
}
@media screen and (max-width: 768px) {
  .p-confirm-btn__submit::after {
    width: 2.083vw;
    height: 2.865vw;
    right: 6.771vw;
  }
}

.p-thanks {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .p-thanks {
    margin-top: 6.51vw;
  }
}
.p-thanks__text {
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
}
@media screen and (max-width: 768px) {
  .p-thanks__text {
    font-size: 3rem;
  }
}
.p-thanks__text:not(:first-child) {
  margin-top: 44px;
}
@media screen and (max-width: 768px) {
  .p-thanks__text:not(:first-child) {
    margin-top: 6.25vw;
  }
}
.p-thanks__btn {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .p-thanks__btn {
    margin-top: 13.672vw;
  }
}
.p-thanks .c-button-s span::before {
  content: none;
}

.p-404 {
  padding: 52px 0 296px;
}
@media screen and (max-width: 768px) {
  .p-404 {
    padding: 7.813vw 0 15.625vw;
  }
}
.p-404__text {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  line-height: 1.8;
}
@media screen and (max-width: 768px) {
  .p-404__text {
    font-size: 3rem;
    line-height: 1.6;
  }
}
@media screen and (max-width: 768px) {
  .p-404__text:not(:first-child) {
    margin-top: 6.51vw;
  }
}
.p-404__btn {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .p-404__btn {
    margin-top: 13.021vw;
  }
}
.p-404 .c-button-s span::before {
  content: none;
}

.u-separate {
  margin: 24px 0;
}