@use "../global" as global;

.c-button {
  &__link {
    max-width: 598px;
    width: 100%;
    margin: 0 auto;
    padding: 44px 0;
    display: block;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1;
    background: global.$btn-color;
    transform: translateY(-4px);
    box-shadow: 0 8px 0 0 #ffba00;
    border-radius: 16px;
    text-align: center;
    position: relative;
    transition: 0.1s linear;
    @include global.mq(sp) {
      max-width: 86.979vw;
      font-size: 4rem;
      box-shadow: 0 1.04vw 0 0 #ffba00;
      border-radius: 2.344vw;
      padding: 5.729vw 0 6.38vw;
    }
    @media (any-hover: hover) {
      &:hover {
        @media screen and (min-width:769px){
          transform: translateY(4px);
          box-shadow: none;
          opacity: 1;
        }
      }
    }
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      right: 54px;
      width: 13px;
      height: 18px;
      transform: translateY(-50%);
      background: url(../img/common/icon_arrow_contact.svg) no-repeat center center / contain;
      @include global.mq(sp) {
        width: 2.083vw;
        height: 2.865vw;
        right: 6.771vw;
      }
    }
  }
  span {
    padding-left: 30px;
    position: relative;
    @include global.mq(sp) {
      padding-left: 1.042vw;
    }
    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: -34px;
      width: 50px;
      height: 50px;
      transform: translateY(-50%);
      background: url(../img/common/icon_mail.svg) no-repeat center center / contain;
      @include global.mq(sp) {
        width: 7.813vw;
        height: 7.813vw;
        left: -10.937vw;
      }
    }
  }
}
