@use "../global" as global;

.p-global-nav {
  text-align: center;
  display: flex;
  justify-content: center;
  @include global.mq(sp){
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 4.688vw;
  }
  &__item {
    // display: inline-block;
    color: global.$text-color;
    line-height: 1;
    &:not(:first-child) {
      margin: 0 0 0 50px;
      @media screen and (max-width:920px){
        margin: 0 0 0 20px;
      }
      @include global.mq(sp){
        margin: 0;
      }
    }
    @include global.mq(sp){
      padding: 5.89vw 0;
      // padding: 6.5% 0;
      a{
        font-size: 3.6rem;
      }
      &.is-pc{
        display: none;
      }
    }
    @media (min-aspect-ratio: 1/1.4) and (max-width: 768px) {
      padding: 4vw 0;
    }
  }
  &__link {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2.1px;
    // line-height: 1;
    position: relative;
    padding-bottom: 3px;
    @include global.mq(sp){
      padding-bottom: 0;
    }
    &::after {
      content: "";
      width: 0;
      height: 1px;
      max-width: 100%;
      margin: 0 auto;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: global.$text-color;
      transition: 0.3s;
    }
    &:hover{
      opacity: 1;
      @media screen and (min-width:769px){
        @media (any-hover: hover) {
          &::after{
            width: 100%;
          }
        }
      }
    }
  }
}

// drawer
.drawer-hamburger{
  display: none;
}
@include global.mq(sp){
  .drawer-hamburger {
    display: block;
    background-color: #CD2438 !important;
    width: 15.625vw;
    height: 15.625vw;
    padding: 0;
    z-index: map-get(global.$layer, "drawer");
  }
  .drawer-hamburger-icon {
    background-color: #fff;
    width: 7.813vw;
    margin: 0 auto;
    height: 0;
    &::before,
    &::after {
      background-color: #fff;
    }
  }
  .drawer-hamburger-icon::before{
    top: -1.562vw;
    height: 0.39vw;
  }
  .drawer-hamburger-icon:after{
    top: 1.562vw;
    height: 0.39vw;
  }
  .drawer-nav {
    // top: 15.625vw;
    margin-top: 15.625vw;
    // height: calc(100dvh - 15.625vw);
    // height: fit-content;
    // height: 163.802vw;
    height: 100dvh;
    margin-bottom: 15.625vw;
  }
  .drawer--right .drawer-nav {
    right: -100%;
  }
  .drawer--right.drawer-open .drawer-hamburger{
    right: 0;
  }
  .drawer-overlay {
    // background-color: rgba(0, 0, 0, 0.3);
    background-color: #fff;
  }
}
