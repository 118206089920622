@use "../global" as global;

.l-404{
  margin-top: 180px;
  @include global.mq(sp){
    margin-top: 15.625vw;
  }
  &__contents{
    margin-top: 100px;
    @include global.mq(sp){
      margin-top: 6.51vw;
    }
  }
}