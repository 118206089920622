@use "../global" as global;

.p-confirm-btn {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px 0;
  @include global.mq(sp) {
    flex-direction: column;
    padding: 50px 20px 0;
  }
  &__return {
    max-width: 270px;
    width: 100%;
    margin: 0 auto;

    display: block;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    background: global.$btn-color;
    transform: translateY(-2px);
    box-shadow: 0 4px 0 0 #ffba00;
    border-radius: 8px;
    text-align: center;
    position: relative;
    transition: 0.1s linear;
    height: fit-content;
    [type="button"] {
      background-color: transparent;
      border: none;
      color: #434343;
      cursor: pointer;
      width: 100%;
      padding: 21px 0;
      @include global.mq(sp) {
        padding: 4.167vw 0 4.427vw;
      }
    }
    @include global.mq(sp) {
      font-size: 2.4rem;
      max-width: 58.594vw;
      box-shadow: 0 0.7vw 0 0 #ffba00;
      border-radius: 1.432vw;
    }
    @media (any-hover: hover) {
      &:hover {
        @media screen and (min-width:769px){
          transform: translateY(2px);
          box-shadow: none;
          opacity: 1;
        }
      }
    }
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 24px;
      width: 6px;
      height: 8px;
      transform: translateY(-50%);
      background: url(../img/contact/icon_arrow_return.svg) no-repeat center center / contain;
      @include global.mq(sp) {
        width: 1.302vw;
        height: 1.823vw;
        right: 4.297vw;
      }
    }
  }
  &__submit {
    position: relative;
    display: inline-block;
    max-width: 598px;
    width: 100%;
    margin: 0 auto;
    margin-left: 40px;
    display: block;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1;
    background: global.$btn-color;
    transform: translateY(-4px);
    box-shadow: 0 8px 0 0 #ffba00;
    border-radius: 16px;
    text-align: center;
    position: relative;
    transition: 0.1s linear;
    @include global.mq(sp) {
      max-width: 78.125vw;
      font-size: 3.8rem;
      box-shadow: 0 1.04vw 0 0 #ffba00;
      border-radius: 2.344vw;
      margin-top: 7.813vw;
      margin-left: auto;
    }
    @media (any-hover: hover) {
      &:hover {
        @media screen and (min-width:769px){
          transform: translateY(4px);
          box-shadow: none;
          opacity: 1;
        }
      }
    }
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      right: 54px;
      width: 13px;
      height: 18px;
      transform: translateY(-50%);
      background: url(../img/common/icon_arrow_contact.svg) no-repeat center center / contain;
      @include global.mq(sp) {
        width: 2.083vw;
        height: 2.865vw;
        right: 6.771vw;
      }
    }
  }
}
