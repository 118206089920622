@use "../global" as global;

.p-footer-cta{
  background-color: #fff;
  padding: 11px 0;
  width: 100%;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity .5s ease, transform .5s ease;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 1;
  pointer-events: none; /* 非表示時のクリックイベントを無効化 */
  @include global.mq(sp){
    padding: 2.214vw 0;
  }
  &.visible{
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto; /* 表示時のクリックイベントを有効化 */
  }
}